import PropTypes from 'prop-types';
import React, { createContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

export const CompetitionContext = createContext({});

export function CompetitionContextProvider({ children }) {
  const [competition, setCompetition, clearCompetition] = useLocalStorage('competition', '');

  return useMemo(
    () => (
      <CompetitionContext.Provider
        value={{
          clearCompetition,
          competition,
          setCompetition
        }}
      >
        {children}
      </CompetitionContext.Provider>
    ),
    [clearCompetition, competition, setCompetition, children]
  );
}

CompetitionContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
