import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import TheatersIcon from '@material-ui/icons/Theaters';
import clsx from 'clsx';
import { get } from 'lodash';
import mimeTypes from 'mime-types';
import React, { useContext, useMemo } from 'react';

import { TokenContext } from '../../contexts/TokenContext';
import useMinioToAppServer from '../../helpers/useMinioToAppServer';
import styles from './AOMContentSmartPreview.module.css';

const AOMContentSmartPreview = (props) => {
  let { aom, sequestered } = props;
  const { token } = useContext(TokenContext.Dynamic);
  const urlMaker = useMinioToAppServer();
  sequestered = false;
  const { figures, title } = aom;
  // @todo handle case where there is other media and its not shown in the AOM preview
  // @see http://localhost:3001/gallery/09ca4d1302b7e9f3f6e54969ba8c5e2647762880587529b26f216084359d15ad
  let firstUri = get(figures, '[0].media[0].uri');
  const type = mimeTypes.lookup(firstUri);
  if (firstUri) {
    firstUri = urlMaker(firstUri, token);
  }
  // const firstPara = get(find(content, ({ Type }) => Type === 'Paragraph'), 'Content.[0].Content.[0]');
  return useMemo(
    () => (
      <div className={clsx({ [styles.wrapper]: true, [styles.sequestered]: sequestered })}>
        {firstUri && type.indexOf('image/') === 0 && (
          <div
            className={styles.imgWrapper}
            style={{
              backgroundImage: `url(${firstUri})`
            }}
          ></div>
        )}
        {firstUri && type.indexOf('audio/') === 0 && (
          <div className={styles.mediaWrapper}>
            <AudiotrackIcon />
          </div>
        )}
        {firstUri && type.indexOf('video/') === 0 && (
          <div className={styles.mediaWrapper}>
            <TheatersIcon />
          </div>
        )}
        <p className={styles.title}>
          {sequestered ? 'This AG is sequestered and not viewable. Sorry!' : title}
        </p>
        {/* {firstPara && <p className={styles.firstPara}>{firstPara}</p>} */}
        {/* {tags && <span>
        {tags.map((tag) =>
          <Chip
            key={tag}
            variant="outlined"
            size="small"
            label={tag}
          />)
        }</span>} */}
        {/* {firstPara && <span>{firstPara}</span>} */}
      </div>
    ),
    [firstUri, sequestered, title, type]
  );
};

export default AOMContentSmartPreview;
