import PropertyAnnotations from './PropertyAnnotations';

const Node = (props) => {
  return `
${props.evidenceType}
${props.instance}
${PropertyAnnotations(props)}
  `;
  // label
};

export default Node;
