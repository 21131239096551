import PropTypes from 'prop-types';
import React, { createContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

export const NamespaceContext = createContext({});

export function NamespaceContextProvider({ children }) {
  const [namespace, setNamespace, removeNamespace] = useLocalStorage('namespace', '');

  return useMemo(
    () => (
      <NamespaceContext.Provider
        value={{
          namespace,
          removeNamespace,
          setNamespace
        }}
      >
        {children}
      </NamespaceContext.Provider>
    ),
    [namespace, removeNamespace, setNamespace, children]
  );
}

NamespaceContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
