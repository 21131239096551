// import { scaleOrdinal } from 'd3-scale';
import { chain, get } from 'lodash';
import React from 'react';

const LLRGauge = (props) => {
  const {
    node,
    scale = {
      Affirm: {
        high: Infinity,
        low: 0.000000000000000000001
      },
      Neutral: {
        high: 0,
        low: 0
      },
      Refute: {
        high: -0.0000000000000000001,
        low: -Infinity
      }
    }
    // domain = [-Infinity, Infinity],
    // range = ['👎', '👍']
    // domain = [-Infinity, 0, Infinity],
    // range = ['REFUTE', 'NEUTRAL', 'AFFIRM']
    // domain = [-Infinity, -1, 0, 1, Infinity],
    // range = ['STRONG REFUTE', 'REFUTE', 'NEUTRAL', 'AFFIRM', 'STRONG AFFIRM']
    // domain = [-Infinity, -5, 0, 5, Infinity],
    // range = ['IS', 'IS LIKELY', 'MAYBE', 'IS LIKELY NOT', 'IS NOT']
  } = props;

  function getTextFromScore(score) {
    return chain(scale).pickBy(textHasScoreRange).keys().value();

    function textHasScoreRange(rangeObj) {
      return rangeObj.low <= score && rangeObj.high >= score;
    }
  }

  const llr = get(node, 'score.score', undefined);

  if (!llr) {
    return null;
  }

  const scaled = getTextFromScore(llr);

  let color = 'black';
  return <>{llr !== undefined && <span style={{ color }}>{scaled}</span>}</>;
};

export default LLRGauge;
