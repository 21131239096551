import PropertyAnnotations from './PropertyAnnotations';

const Node = (props) => {
  return `
${props.nodeType}
${PropertyAnnotations(props)}
  `;
  // hole
  // points EvPoint [{x, y}]
};

export default Node;
