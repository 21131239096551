import { IconButton, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu"
import clsx from "clsx";
import { useContext } from "react";

import { NavDrawerContext } from "../../contexts/NavDrawerContext";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 36,
  }
}));

const NavDrawerToggle = () => {
  const classes = useStyles();
  const { open, toggleOpen } = useContext(NavDrawerContext);
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={toggleOpen}
      edge="start"
      className={clsx(classes.menuButton, {
        [classes.hide]: open,
      })}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default NavDrawerToggle;
