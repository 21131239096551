const Property = (props) => {
  let label = '';
  if (props.annotations) {
    label = `
Annotations:
${Object.keys(props.annotations).map((key) => `${key}: ${props.annotations[key]}`)}
`;
  }
  return label;
};

export default Property;
