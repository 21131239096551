import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Form from '@rjsf/material-ui';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { SettingsContext } from '../../contexts/SettingsContext';
import { TokenContext } from '../../contexts/TokenContext';
import safeParseApiJson from '../../helpers/safeParseApiJson';
import schema from './../../schemas/AnalyticWorkflow.schema.json';

// @todo this won't work since height on main wrapper is set to be full height
// apply that conditionally or just make this full height and scroll inside. Along
// with other places where it overflows, like tab panel
const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2)
  }
}));

const WorkflowForm = (props) => {
  const { name } = props;
  const { workflowId } = useParams();
  const { connection } = useContext(SettingsContext);
  const { token } = useContext(TokenContext.Dynamic);
  const classes = useStyles();
  const url = `${connection}/analyticworkflows/${name || workflowId}`;
  const { isLoading, data = {} } = useQuery(url, () =>
    fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.text())
      .then(safeParseApiJson)
  );

  // if (isLoading) return 'Loading...'

  // if (error) return 'An error has occurred: ' + error.message

  // if (data.status === 500) {
  //   return (
  //     <>
  //       <h1>Error</h1>
  //       <pre>{JSON.stringify(data, null, 2)}</pre>
  //     </>
  //   )
  // }

  if (data.status && data.status !== 500) {
    schema.readOnly = true;
  }

  return (
    <Paper className={classes.paper}>
      <Box p={3}>
        {isLoading && 'Loading...'}
        {!isLoading && <Form schema={schema} formData={data} />}
      </Box>
    </Paper>
  );
};

export default WorkflowForm;
