
import { Box, Grid, Paper, StepContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useRef } from 'react';

import IngestPanel from '../IngestPanel';
import AnalyticsCatalogTable from '../tables/AnalyticsCatalogTable';
import TagPanelFilter from '../TagPanelFilter';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  paper: {
    height: '100%',
    marginLeft: theme.spacing(2),
    overflow: 'auto',
    position: 'relative'
  },
  paperLeft: {
    height: '100%',
    overflow: 'auto',
    position: 'relative'
  }
}));

const steps = [
  {
    directions: 'This group name will organize all of the media you ingest.',
    label: 'Create a group name'
  },
  {
    directions: 'Drag and drop or select from your computer one of the supported multimedia formats.',
    label: 'Select Media'
  },
  {
    directions: 'Choose from 1 or more analytics to process your multimedia.',
    label: 'Select Analytics'
  },
  {
    component: null,
    directions: 'The system will take some time to upload your multimedia and run the various workflows. Please check back later to see the results.',
    label: 'Start Upload'
  }
];


export default function HorizontalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const urlIngester = useRef(null);
  const aomIngester = useRef(null);

  const handleButtonClick = () => {
    urlIngester.current.dispatchEvent(
      new Event("submit", { bubbles: true, cancelable: true })
    )
    aomIngester.current.dispatchEvent(
      new Event("submit", { bubbles: true, cancelable: true })
    )
  }

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // eslint-disable-next-line no-unused-vars
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  
  return (
    <>
      {/* <Stepper activeStep={activeStep} component={Paper}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper> */}
      <Grid container>
        <Grid item xs={4}>
          <Paper className={classes.paperLeft}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map(({ directions, label }, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    <Typography>{directions}</Typography>
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1 ? 'Done' : 'Next'}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
              <br />
              <br />
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  {/* <Typography>All steps completed - you&apos;re finished</Typography> */}
                  <Button onClick={handleReset} className={classes.button}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Stepper>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <Box p={2}>
              {activeStep === 0 && (
                <TagPanelFilter />
              )}
              <div hidden={activeStep !== 1}>
                <IngestPanel ref={urlIngester} ingestType="aom" title="Ingest Pre-Processed SemaFor AOMs" />
                <IngestPanel ref={aomIngester} ingestType="url" title="Ingest Supported URLs" />
              </div>
              {activeStep === 2 && <AnalyticsCatalogTable />}
              {activeStep === 3 && <Button size="large" variant="contained" color="primary" onClick={handleButtonClick} >Start Upload</Button>}
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item xs={3}>
            <Paper>
              <Box p={2}>
                Results
              </Box>
            </Paper>
        </Grid> */}
      </Grid>
    </>
  );
}
