import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

export const AgCollectionContext = createContext({});

export function AgCollectionProvider({ children }) {
  const [collectionIds, setCollectionIds, removeCollectionIds] = useLocalStorage('agIds', []);

  const addToCollection = useCallback(
    (id) => {
      if (!collectionIds.includes(id)) {
        setCollectionIds([...collectionIds, id]);
      }
    },
    [collectionIds, setCollectionIds]
  );

  const removeFromCollection = useCallback(
    (id) => {
      if (collectionIds.includes(id)) {
        setCollectionIds([...filter(collectionIds, (_id) => _id !== id)]);
      }
    },
    [collectionIds, setCollectionIds]
  );

  const isInCollection = useCallback(
    (id) => {
      return collectionIds.includes(id);
    },
    [collectionIds]
  );

  const resetCollection = useCallback(
    (id) => {
      setCollectionIds([id]);
    },
    [setCollectionIds]
  );

  return useMemo(
    () => (
      <AgCollectionContext.Provider
        value={{
          addToCollection,
          collectionIds,
          isInCollection,
          removeCollectionIds,
          removeFromCollection,
          resetCollection,
          setCollectionIds
        }}
      >
        {children}
      </AgCollectionContext.Provider>
    ),
    [
      removeFromCollection,
      isInCollection,
      collectionIds,
      addToCollection,
      setCollectionIds,
      removeCollectionIds,
      children,
      resetCollection
    ]
  );
}

AgCollectionProvider.propTypes = {
  children: PropTypes.node.isRequired
};
