import { useKeycloak } from '@react-keycloak/web';
import React, { createContext, useMemo } from 'react';

import { useUpdatingRef } from '../hooks/useUpdatingRef';

const RolesContext = {
  Dynamic: createContext({}),
  Static: createContext({})
};

const StaticProvider = ({ children, roles }) => {
  const rolesRef = useUpdatingRef(roles);

  const value = useMemo(() => ({ rolesRef }), [rolesRef]);

  return useMemo(
    () => <RolesContext.Static.Provider value={value}>{children}</RolesContext.Static.Provider>,
    [children, value]
  );
};

const DynamicProvider = ({ children }) => {
  const { keycloak } = useKeycloak();
  const roles = useMemo(() => keycloak?.tokenParsed?.client_roles ?? [], [keycloak?.tokenParsed?.client_roles]);

  const value = useMemo(() => ({ roles }), [roles]);

  return useMemo(
    () => (
      <RolesContext.Dynamic.Provider value={value}>
        <StaticProvider roles={roles}>{children}</StaticProvider>
      </RolesContext.Dynamic.Provider>
    ),
    [children, roles, value]
  );
};

export { RolesContext, DynamicProvider as RolesProvider };
