import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import React, { useContext, useMemo } from "react";

import ConnectionDialog from "./components/ConnectionDialog";
import MainContent from "./components/MainContent";
import { AgCollectionProvider } from "./contexts/AgCollectionContext";
import { AnalyticsProvider } from "./contexts/AnalyticsContext";
import { CollectionProvider } from "./contexts/CollectionContext";
import { CompetitionContextProvider } from "./contexts/CompetitionContext";
import { NamespaceContextProvider } from "./contexts/NamespaceContext";
import { NavDrawerContextProvider } from "./contexts/NavDrawerContext";
import { RolesProvider } from "./contexts/RolesContext";
import { SettingsContext } from "./contexts/SettingsContext";
import { TagContextProvider } from "./contexts/TagContext";
import { TokenProvider } from "./contexts/TokenContext";
import { UsernameProvider } from "./contexts/UsernameContext";
import useSemaforKeycloak from "./hooks/useSemaforKeycloak";

const initOptions = {
  onLoad: 'login-required' 
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  }
}));

function App() {
  const { reset } = useContext(SettingsContext);
  const keycloak = useSemaforKeycloak();
  const classes = useStyles();
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          // @todo hook into user preference
          type: "light",
        },
      }),
    []
  );
  return useMemo(
    () => (
      <ReactKeycloakProvider
        onEvent={(event, error) => {
          console.log(event);
          if (event === "onInitError") {
            console.log('init error keycloak');
            reset();
          }
        }}
        authClient={keycloak}
        initOptions={initOptions}
      >
        <TokenProvider>
          <RolesProvider>
            <NavDrawerContextProvider>
              <ThemeProvider theme={theme}>
                <CompetitionContextProvider>
                  <CollectionProvider>
                    <NamespaceContextProvider>
                      <TagContextProvider>
                        <AnalyticsProvider>
                          <AgCollectionProvider>
                            <UsernameProvider>
                              <div className={classes.root}>
                                <CssBaseline />
                                <MainContent />
                                <ConnectionDialog />
                              </div>
                            </UsernameProvider>
                          </AgCollectionProvider>
                        </AnalyticsProvider>
                      </TagContextProvider>
                    </NamespaceContextProvider>
                  </CollectionProvider>
                </CompetitionContextProvider>
              </ThemeProvider>
            </NavDrawerContextProvider>
          </RolesProvider>
        </TokenProvider>
      </ReactKeycloakProvider>
    ),
    [classes.root, keycloak, reset, theme]
  );
}

export default App;
