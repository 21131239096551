import { get } from 'lodash';

import nodeColors from '../constants/nodeColors';

export default function formatNodesForGraph(nodes) {
  return nodes.map((node) => {
    const nodeType = get(node, 'nodeType');
    const color = get(nodeColors, [nodeType, 'color'], 'gray');
    const fontSize = get(nodeColors, [nodeType, 'fontSize'], 8);
    const strokeWidth = get(nodeColors, [nodeType, 'strokeWidth'], 1);
    const fontWeight = get(nodeColors, [nodeType, 'fontWeight'], 'normal');
    return {
      ...node,
      // additional properties
      color,

      fontSize,
      fontWeight,
      originalNode: node,
      strokeColor: 'black',
      strokeWidth
    };
  });
}
