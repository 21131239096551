import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';

import { SettingsContext } from '../../contexts/SettingsContext';
import { TokenContext } from '../../contexts/TokenContext';
import safeParseApiJson from '../../helpers/safeParseApiJson';

// @todo this must already be inside of queryClient? Can we put it at root?
// and then remove queryClient from each individual component. Need to wrap Stories.
const LoadWorkflowsForAg = (props) => {
  const { connection } = useContext(SettingsContext);
  const { token } = useContext(TokenContext.Dynamic);
  const { agId, limit, refetchInterval, renderComponent: RenderComponent, workflowName } = props;
  const filter = { 'spec.artifactGraph.name': { $eq: agId }, 'spec.workflow': { $size: 1 } };
  if (workflowName) {
    filter['spec.workflow.components.name'] = { $eq: workflowName };
  }
  const uri = `${connection}/analyticworkflows?filter=${JSON.stringify(filter)}${
    limit ? `&limit=${limit}&order={"metadata.creationTimestamp": -1}` : '' // @todo verify the order works // @todo change
  }`;
  const { data, error, isLoading } = useQuery(
    `analyticworkflows-${agId}-${workflowName}`,
    () =>
      fetch(uri, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => res.text())
        .then(safeParseApiJson),
    {
      refetchInterval,
      refetchOnWindowFocus: false,
      staleTime: 10 * 100 * 60
    }
  );
  return useMemo(
    () => (
      <>
        {isLoading && 'Loading Workflows for AG'}
        {error && 'Error loading Workflows for AG'}
        {data && data.items.length >= 0 && (
          <RenderComponent workflows={data.items.slice().reverse()} {...props} />
        )}
      </>
    ),
    [isLoading, error, data, props]
  );
};

export default LoadWorkflowsForAg;
