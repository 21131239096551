import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React, { forwardRef, useMemo } from "react";
import { Link, useLocation } from 'react-router-dom';

function ListItemLink(props) {
  const { disabled, icon, primary, to } = props;
  const location = useLocation();
  const renderLink = useMemo(
    () => forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to]
  );
  const isSelected = useMemo(() => location.pathname === to, [location.pathname, to]);
  return useMemo(
    () => (
      <ListItem button component={renderLink} selected={isSelected} disabled={disabled}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText style={{ whiteSpace: 'nowrap' }} primary={primary} />
      </ListItem>
    ),
    [disabled, icon, isSelected, primary, renderLink]
  );
}

export default ListItemLink