import { forIn, get, reduce, some } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useQueries, useQuery } from 'react-query';

import { SettingsContext } from '../../contexts/SettingsContext';
import { TokenContext } from '../../contexts/TokenContext';
import safeParseApiJson from '../../helpers/safeParseApiJson';

const LoadEgsForAg = (props) => {
  // @todo use ag props
  const { ag, agId, agNamespace, renderComponent: RenderComponent } = props;
  const { connection } = useContext(SettingsContext);
  const { token } = useContext(TokenContext.Dynamic);

  let augmentedAgName = '-1'; // fake name
  let augmentedAgNamespace = '-1';

  if (ag.associatedGraphs) {
    const augmentedAg = ag.associatedGraphs[0];
    augmentedAgName = augmentedAg.graphReference.name;
    augmentedAgNamespace = augmentedAg.graphReference.namespace;
    console.log(
      'this ag has associated graphs',
      ag.associatedGraphs,
      augmentedAgName,
      augmentedAgNamespace
    );
  }

  const fetchAgForAag = () => {
    // @todo match namespace on all of these queries on all components
    const filter = {
      $and: [
        { 'metadata.name': { $eq: augmentedAgName } },
        // @todo this will never show the purdue AAGs because of bug in their analytic
        // where it reports namespace default all the time.
        { 'metadata.namespace': { $eq: augmentedAgNamespace } }
      ]
    };
    return fetch(`${connection}/artifactgraphs?filter=${JSON.stringify(filter)}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.text())
      .then(safeParseApiJson);
  };

  const { data: data2 = [] } = useQuery([augmentedAgName, augmentedAgNamespace], fetchAgForAag, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 10 * 100 * 60 // 5 minutes
  });

  // const fetchEgsThatReferenceThisAg = () => {
  //   const filter = { 'associatedGraphs.graphReference.name': { $eq: agId } };
  //   return fetch(`${connection}/evidencegraphs?filter=${JSON.stringify(filter)}`)
  //     .then((res) => res.text())
  //     .then(safeParseApiJson);
  // };

  const fetchEgsThatReferenceThisAg = () => {
    const filter = {
      $and: [
        { 'associatedGraphs.graphReference.name': { $eq: agId } },
        { 'associatedGraphs.graphReference.namespace': { $eq: agNamespace } }
        // @here { 'nodes.analytic': { $in: collectionIds } }
      ]
    };
    return fetch(`${connection}/evidencegraphs?filter=${JSON.stringify(filter)}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.text())
      .then(safeParseApiJson);
  };

  const fetchAgsThatReferenceThisAg = () => {
    const filter = {
      $and: [
        { 'associatedGraphs.graphReference.name': { $eq: agId } },
        // @here { 'componentRef.name': { $in: collectionIds } },
        { 'associatedGraphs.graphReference.namespace': { $eq: agNamespace } },
        { 'metadata.labels.subkind': { $eq: 'AugmentedArtifactGraph' } }
      ]
    };
    return fetch(`${connection}/artifactgraphs?filter=${JSON.stringify(filter)}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.text())
      .then(safeParseApiJson);
  };

  const results = useQueries([
    {
      keepPreviousData: true,
      queryFn: fetchEgsThatReferenceThisAg,
      queryKey: [agId, agNamespace, 'evidenceGraphs'],
      refetchOnWindowFocus: false,
      staleTime: 10 * 100 * 60
    },
    {
      keepPreviousData: true,
      queryFn: fetchAgsThatReferenceThisAg,
      queryKey: [agId, agNamespace, 'Aags'],
      refetchOnWindowFocus: false,
      staleTime: 10 * 100 * 60
    }
  ]);

  if (agId === '643d788c71b81277555b90a2fe49239a512ffbb2f3155a5f7921f162079ab506') {
    console.log(results);
  }

  const data = reduce(
    results,
    (acc, result) => {
      if (result.data && result.data.items) {
        return [...acc, ...result.data.items];
      }
      return acc;
    },
    []
  );

  const isAnyFetching = some(results, (r) => {
    return r.isFetching;
  });

  // // process artifact and evidence edges the same
  const edges = reduce(
    [...data, ag, ...get(data2, 'items', [])],
    (acc, { edges, metadata: { name } }) => [
      ...acc,
      ...edges.map((edge) => ({
        ...edge,
        id: `${name}-${edge.id}`,
        source: `${name}-${edge.source}`,
        target: `${name}-${edge.target}`
      }))
    ],
    []
  );

  // // process evidence graphs
  const nodes = reduce(
    data,
    (acc, { metadata: { name }, nodes }) => [
      ...acc,
      ...nodes.map((node) => {
        const _node = {
          ...node,
          id: `${name}-${node.id}`
        };
        if (_node.graphId) {
          _node.graphId = `${name}-${_node.graphId}`;
        }
        return _node;
      })
    ],
    []
  );

  if (ag) {
    // artifact graphs need a fake node created
    [ag, ...get(data2, 'items', [])].forEach(
      ({ metadata: { name }, nodes: _nodes, rootNodeId }) => {
        _nodes.forEach((_node) => {
          const newNode = {
            ..._node,
            id: `${name}-${_node.id}`
          };
          nodes.push(newNode);
        });
      }
    );
  }

  const allAssociatedGraphs = useMemo(() => {
    const _allAssociatedGraphs = {};
    data.forEach(({ associatedGraphs, metadata: { name } }) => {
      forIn(associatedGraphs, (ag, key) => {
        _allAssociatedGraphs[`${name}-${key}`] = ag;
      });
    });
    return _allAssociatedGraphs;
  }, [data]);

  data.forEach(({ associatedGraphs, metadata: { name } }) => {
    forIn(associatedGraphs, (ag, key) => {
      allAssociatedGraphs[`${name}-${key}`] = ag;
    });
  });

  const allData = useMemo(() => [...data, ag], [data, ag]);

  return useMemo(
    () => (
      <>
        {!isAnyFetching && (
          <RenderComponent
            graphs={allData}
            id={agId}
            layout={{
              name: 'dagre',
              nodeDimensionsIncludeLabels: true
            }}
            ag={ag}
            agId={agId}
            agNamespace={agNamespace}
            nodes={nodes}
            edges={edges}
            associatedGraphs={allAssociatedGraphs}
          />
        )}
        {/* <GraphView
        id={agId}
        nodes={nodes}
        edges={edges}
        associatedGraphs={allAssociatedGraphs}
        showAg
        doLayout
        detailedNode
      /> */}
      </>
    ),
    [isAnyFetching, allData, agId, ag, agNamespace, nodes, edges, allAssociatedGraphs]
  );
};

export default LoadEgsForAg;
