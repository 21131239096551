const Node = (props) => {
  return `
${props.nodeType}
  `;
  // annotations
  // offset
  // length
};

export default Node;
