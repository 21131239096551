import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';

import { version } from './../../../package.json';
import { drawerWidth } from './../../constants/navigation.js';
import AppServerStatus from './../AppServerStatus';
import HasAuthRole from './../HasAuthRole';
import HelpButton from './../HelpButton';
import UserMenu from './../UserMenu';

const useStyles = makeStyles((theme) => ({
  account: {
    marginRight: -10
  },
  appBar: {
    '& > .MuiToolbar-gutters': {
      paddingLeft: 16,
      paddingRight: 16
    },
    background: '#292961',
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    }),
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp
    }),
    width: `calc(100% - ${drawerWidth}px)`
  },
  children: {
    display: 'flex',
    flexGrow: 1,
    margin: '0 100px'
  },
  img: {
    display: 'inherit',
    flexGrow: 1,
    marginLeft: -10
  },
  version: {
    color: 'gray',
    marginLeft: 15
  }
}));

function TopBar(props) {
  const classes = useStyles();
  const { children, menu, open, showLogo = true } = props;

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}
    >
      <Toolbar>
        {menu && !open && menu}
        {showLogo && (
          <Typography variant="h6" className={classes.title}>
            <img
              src="https://gitlab.semaforprogram.com/uploads/-/system/appearance/header_logo/1/SemaFor-Logo-Scale.png"
              className={classes.img}
              width="140"
              alt="SemaFor logo"
            />
          </Typography>
        )}
        {showLogo && <span className={classes.version}>v{version}</span>}
        <div className={classes.children}>{children}</div>
        <AppServerStatus />
        <HelpButton />
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
