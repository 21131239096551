import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { find, get, isEmpty, reduce } from 'lodash';
import mimeTypes from 'mime-types';
import React, { useContext, useState } from 'react';

import { TokenContext } from '../../contexts/TokenContext';
import useMinioToAppServer from '../../helpers/useMinioToAppServer';
import MediaPlayer from '../MediaPlayer';
import MetadataViewer from './../MetadataViewer';

const AomViewer = (props) => {
  const urlMaker = useMinioToAppServer();
  const { token } = useContext(TokenContext.Dynamic);
  const { aom } = props;

  const [state, setState] = useState({
    showIds: false,
    showMetadata: false
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const allImages = reduce(
    aom.figures,
    (acc, fig) => {
      return [...acc, ...fig.media];
    },
    []
  );

  function formatter(c) {
    return (
      <React.Fragment>
        {state.showIds && <Chip variant="outlined" size="small" label={c.ID} />}
        {c.Type === 'Link' && (
          <a href={c.Uri} target="_blank" rel="noreferrer">
            {recurseContent(c)}
          </a>
        )}
        {c.Type === 'Text' && <p>{recurseContent(c)}</p>}
      </React.Fragment>
    );
  }

  function recurseContent(c, type) {
    return c.Content.map((subC) => {
      if (!subC.ID) {
        return <React.Fragment key={subC}>{subC}</React.Fragment>;
      } else {
        return <React.Fragment key={subC.ID || subC}>{formatter(subC)}</React.Fragment>;
      }
    });
  }

  // @todo video http://localhost:3001/gallery/b584377833be2d7736613e6a9fe92364c720f56efdeaad6bfa386ab789ae671a
  function formatMedia(c) {
    const semaforStoredImage = find(allImages, ({ source_uri, uri }) => {
      return source_uri === c.SourceUri || uri.indexOf(c.SourceUri >= -1);
    });
    if (semaforStoredImage) {
      const uri = urlMaker(semaforStoredImage.uri, token);
      const type = mimeTypes.lookup(semaforStoredImage.uri);
      if (type.indexOf('image/') === 0) {
        return <img style={{ maxWidth: '50%' }} alt="article" src={uri} />;
      }
      if (type.indexOf('audio/mpeg') === 0) {
        return (
          <div style={{ maxWidth: '50%' }}>
            <MediaPlayer style={{ maxWidth: '50%' }} audio uri={uri} type={type} />
          </div>
        );
      }
      if (type.indexOf('video/mp4') === 0) {
        return (
          <div style={{ maxWidth: '50%' }}>
            <MediaPlayer video uri={uri} type={type} />
          </div>
        );
      }
      return <p style={{ color: 'red' }}>{type} not yet supported</p>;
    }
    return <p style={{ color: 'red' }}>Error with image {c.SourceUri}</p>;
  }

  function recurseMedia(c) {
    // idx is intentional
    // @todo debug why this format does not have IDs
    return c.Media.map((subC, idx) => {
      return (
        <React.Fragment key={subC.ID || idx}>
          {formatMedia(subC)}
          <p>
            <em>caption: {c.Caption}</em>
          </p>
        </React.Fragment>
      );
    });
  }

  if (isEmpty(aom)) {
    return null;
  }

  return (
    <>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={state.showMetadata}
              onChange={handleChange}
              name="showMetadata"
              color="primary"
            />
          }
          label="Show Metadata"
        />
        <FormControlLabel
          control={
            <Switch
              checked={state.showIds}
              onChange={handleChange}
              name="showIds"
              color="primary"
            />
          }
          label="Debug AOM IDs"
        />
        <FormControlLabel disabled control={<Switch />} label="Show Localization Evidence" />
        <FormControlLabel disabled control={<Switch />} label="Hide Duplicate Images" />
        <FormControlLabel disabled control={<Switch />} label="Apply Formatting" />
      </FormGroup>
      <h1>{aom.title}</h1>
      <p>
        <strong>Source: {get(aom, 'source.name', '')}</strong>
        <br />
        Source URL:{' '}
        <a href={get(aom, 'uri', '')} rel="noreferrer" target="_blank">
          {get(aom, 'uri', '')}
        </a>
      </p>
      {state.showMetadata && <MetadataViewer aom={aom} />}
      {aom.content.map((c) => {
        return (
          <React.Fragment key={c.ID || c}>
            {state.showIds && <Chip variant="outlined" size="small" label={c.ID} />}
            {c.Content && recurseContent(c)}
            {c.Media && recurseMedia(c)}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default AomViewer;
