import PropertyAnnotations from './PropertyAnnotations';

const Node = (props) => {
  // return props.nodeType;
  return `
${props.nodeType}
${props.reason || ''}
${props.explanation || ''}
${PropertyAnnotations(props)}
  `;
  // annotations
  // reason
  // explanation
};

export default Node;
