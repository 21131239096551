import { TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { get, groupBy, reduce, size, sortBy, trim, uniqBy } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useInfiniteQuery } from "react-query";
import { useDebounce, useToggle } from "react-use";

import { AnalyticsContext } from "../../../contexts/AnalyticsContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { TokenContext } from "../../../contexts/TokenContext";
import safeParseApiJson from "../../../helpers/safeParseApiJson";
import ToggleAnalytic from "../../ToggleAnalytic";

const useStyles = makeStyles((theme) => ({
  button: {
    "& > button": {
      opacity: 0.2,
    },
    "& > button.selected": {
      opacity: 1,
    },
  },
  buttonCell: {
    width: 87,
  },
  container: {
    maxHeight: "100%",
  },
  filterButton: {
    "& > button": {
      marginRight: theme.spacing(1),
    },
    marginRight: theme.spacing(2),
  },
  previewCell: {
    padding: "6px 6px 6px 0",
  },
  relative: {
    position: "relative",
  },
  tableRow: {
    "&:hover button": {
      opacity: 1,
    },
    cursor: "pointer",
    transition: theme.transitions.create("backgroundColor", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.sharp,
    }),
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.light, 0.85),
      color: theme.palette.primary.main,
    },
  },
  tableRowSelected: {
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
    color: theme.palette.primary.main,
    // backgroundColor: theme.palette.action.hover
  },
}));

const AnalyticsCatalogTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [val, setVal] = React.useState('');
  const [debouncedValue, setDebouncedValue] = React.useState('');
  const { connection } = useContext(SettingsContext);
  const [count, setCount] = useState(Infinity);
  const { collectionIds } = useContext(AnalyticsContext);
  const { token } = useContext(TokenContext.Dynamic);
  const pageSize = 100;
  const [showCollection, toggleShowCollection] = useToggle(false);

  useDebounce(
    () => {
      setDebouncedValue(val);
    },
    2000,
    [val]
  );

  // @todo do in more performance optimized way
  // also look at App.js List while doing this. Pair with @riley?
  let filter = {};

  if (collectionIds && showCollection) {
    filter["metadata.name"] = {
      $in: collectionIds,
    };
  }

  // @todo hook up search for analytics catalog
  if (debouncedValue && trim(debouncedValue) !== "") {
    filter["$or"] = [
      { "metadata.name": { $regex: debouncedValue } },
      { "poc.name": { $regex: debouncedValue } },
      { "metadata.summary": { $regex: debouncedValue } },
      { "metadata.description": { $regex: debouncedValue } },
    ];
  }

  const fetchComponents = ({ pageParam = undefined }) =>
    fetch(
      `${connection}/components?${
        filter ? `filter=${JSON.stringify(filter)}` : ""
      }&limit=${pageSize}&continueToken=${pageParam ? pageParam : ""}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
      .then((res) => res.text())
      .then(safeParseApiJson);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery(["components", { filter }], fetchComponents, {
    getNextPageParam: (lastPage) => get(lastPage, 'metadata.continueToken'),
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (isFetching) {
        console.warn(
          "Wait until current request is complete to load next page"
        );
        return;
      }
      if (newPage > page && hasNextPage) {
        fetchNextPage().then(() => {
          setPage(newPage);
        });
      } else {
        setPage(newPage);
      }
    },
    [fetchNextPage, page, hasNextPage, isFetching]
  );

  useEffect(() => {
    if (hasNextPage === false) {
      const totalCount = reduce(data.pages, (acc, page) => acc + size(page.items), 0);
      setCount(totalCount);
    }
  }, [data, hasNextPage]);

  useEffect(() => {
    if (!showCollection) {
      setCount(Infinity);
    } else {
      // go back to page 0
      setPage(0);
    }
  }, [showCollection]);

  const pageData = get(data, ["pages", page, "items"], []);
  const reduced = pageData.map((row) => {
    const key = [
      get(row, "metadata.name"),
      get(row, "metadata.creationTimestamp"),
      JSON.stringify(get(row, "status")),
    ];
    const stringVersion = `-${get(row, "version", "").replaceAll(".", "-")}`;
    const nonVersionName = get(row, "metadata.name", "").replace(
      stringVersion,
      ""
    );
    return {
      ...row,
      key,
      nonVersionName,
    };
  });
  const grouped = groupBy(reduced, "nonVersionName");

  const handleSearchChange = useCallback((event) => {
    setVal(event.target.value);
  }, []);

  useEffect(() => {
    setCount(Infinity);
  }, [debouncedValue]);

  return useMemo(
    () => (
      <>
        {error && "An error has occurred: " + error.message}
        {!error && data && data.pages && data.pages.length > 0 && (
          <TableContainer className={classes.container}>
            <Table size="small" aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell component="th" className={classes.buttonCell}>
                    <IconButton
                      className={classes.paginationAreaButton}
                      onClick={() => toggleShowCollection()}
                    >
                      {showCollection ? <StarIcon /> : <StarBorderIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" className={classes.buttonCell} colSpan={2}>
                    <TextField
                      onChange={handleSearchChange}
                      margin="dense"
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                      placeholder="Search name, summary, desc, and POC name"
                    />
                  </TableCell>
                  <TablePagination
                    rowsPerPageOptions={[pageSize]}
                    colSpan={11}
                    count={count} // @todo get from api once added
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={handleChangePage}
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Star</TableCell>
                  <TableCell>Name</TableCell>
                  {/* <TableCell>Type</TableCell> */}
                  <TableCell>Summary</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Repo</TableCell>
                  <TableCell>POC Names</TableCell>
                  <TableCell>GPU?</TableCell>
                  <TableCell>External Resources?</TableCell>
                  {/* <TableCell>Status</TableCell> */}
                </TableRow>
              </TableHead>
              {/* <TableHead>
                <TableRow>
                  <TableCell component="th" className={classes.buttonCell} colSpan={11}>
                    <span className={classes.filterButton}>
                      <IconButton>
                        <SortIcon />
                      </IconButton>
                      Default Sort
                    </span>
                    <span className={classes.filterButton}>
                      <IconButton>
                        <TuneIcon />
                      </IconButton>
                      No Filter
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" className={classes.buttonCell}>
                    <IconButton
                      className={classes.paginationAreaButton}
                      onClick={() => toggleShowCollection()}
                    >
                      {showCollection ? <StarIcon /> : <StarBorderIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.buttonCell}>
                    <TablePagination
                      component="div"
                      colSpan={11}
                      rowsPerPageOptions={[pageSize]}
                      count={count} // @todo get from api once added
                      rowsPerPage={pageSize}
                      page={page}
                      onChangePage={handleChangePage}
                    />
                  </TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                {Object.keys(grouped).map((key) => {
                  const previousVersions = uniqBy(
                    sortBy(grouped[key], (d) =>
                      Date.parse(get(d, "metadata.creationTimestamp"))
                    ).reverse(),
                    "version"
                  );
                  const row = previousVersions[0];
                  return (
                    <React.Fragment key={row.key}>
                      <TableRow key={`${row.key}-latest-version`}>
                        <TableCell
                          className={classes.buttonCell}
                          align="center"
                        >
                          <div className={classes.button}>
                            <ToggleAnalytic id={get(row, "metadata.name")} />
                          </div>
                        </TableCell>
                        <TableCell>{get(row, "nonVersionName")}</TableCell>
                        {/* <TableCell>{get(row, 'config.properties.componentType')}</TableCell> */}

                        <TableCell>{get(row, "metadata.summary")}</TableCell>
                        <TableCell>
                          {get(row, "metadata.description")}
                        </TableCell>
                        <TableCell>
                          <a href={get(row, "repositoryUrl")} target="blank">
                            Gitlab
                          </a>
                        </TableCell>

                        {/* <TableCell>{get(row, 'poc.email')}</TableCell> */}
                        <TableCell>{get(row, "poc.name")}</TableCell>
                        <TableCell>
                          {get(row, "computeResources.gpu") !== 0 ? "GPU" : " "}{" "}
                        </TableCell>
                        <TableCell>
                          {get(row, "externalResources") ? "Yes" : " "}
                        </TableCell>
                        {/* <TableCell>{get(row, 'status.phase')}</TableCell> */}
                      </TableRow>
                      <TableRow key={`${row.key}-previous-versions`}>
                        <TableCell
                          style={{ margin: 0, padding: 0 }}
                          colSpan={10}
                        >
                          <Table size="small" aria-label="purchases">
                            <TableBody>
                              {previousVersions.map((r) => (
                                <TableRow
                                  key={`${r.version}-${r.metadata.creationTimestamp}`}
                                  style={{ height: 20 }}
                                >
                                  <TableCell
                                    className={classes.buttonCell}
                                    align="center"
                                  >
                                    <div className={classes.button}>
                                      <ToggleAnalytic
                                        id={get(r, "metadata.name")}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <span>
                                      {r.version}
                                      {/*  <small>{r.metadata.creationTimestamp}</small> */}
                                      <br />
                                    </span>
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    ),
    [error, data, classes.container, classes.buttonCell, classes.paginationAreaButton, classes.button, showCollection, handleSearchChange, count, page, handleChangePage, grouped, toggleShowCollection]
  );
};

export default AnalyticsCatalogTable;
