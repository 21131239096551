import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext } from 'react';

import { SettingsContext } from '../../contexts/SettingsContext';
import ConnectionSelector from '../ConnectionSelector';

const ConnectionDialog = (props) => {
  const { dialogOpen, dialogToggle } = useContext(SettingsContext)
  return (
    <Dialog onClose={() => {
      dialogToggle(false);
    }} aria-labelledby="simple-dialog-title" open={dialogOpen} fullWidth>
      <DialogTitle id="simple-dialog-title">Connection Settings</DialogTitle>
      <ConnectionSelector />
    </Dialog>
  );
};

export default ConnectionDialog;
