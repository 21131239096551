import { Box, Button, FormGroup, makeStyles, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { compact, truncate } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';

import { AgCollectionContext } from '../../contexts/AgCollectionContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import useUpdatingRef from '../../hooks/useUpdatingRef';
import AvailableAnalytics from '../AvailableAnalytics';
import HelpButton from '../HelpButton';
import ArtifactGraphTable from '../tables/ArtifactGraphTable';

const { REACT_APP_BACKEND_PYTHON_ENDPOINT } = process.env;

const useStyles = makeStyles((theme) => {
  return {
    disabledFormGroup: {
      '> *': {
        cursor: 'not-allowed'
      },
      cursor: 'not-allowed',
      opacity: 0.5,
      pointerEvents: 'none'
    },
    dropzone: {
      border: '1px solid lightgray',
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%'
    },
    helpButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    },
    paper: {
      height: '100%',
      marginLeft: theme.spacing(2),
      overflow: 'auto',
      position: 'relative'
    },
    paperLeft: {
      height: '100%',
      overflow: 'auto',
      position: 'relative'
    }
  };
});

const IngestAOMs = (props) => {
  const { collectionIds } = useContext(AnalyticsContext);
  const { collectionIds: agIds } = useContext(AgCollectionContext);
  const [files, setFiles] = useState([]);
  const [markdownFiles, setMarkdownFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [url, setUrl] = useState('');
  const [urls, setUrls] = useState('');
  const [tag, setTag] = useState('test-3');
  const [fetcher, setFetcher] = useState();

  // @TODO sort on label timestamp
  const imagesRef = useUpdatingRef(images);
  const filesRef = useUpdatingRef(files);
  const markdownFilesRef = useUpdatingRef(markdownFiles);
  const urlRef = useUpdatingRef(url);
  const urlsRef = useUpdatingRef(urls);
  const tagRef = useUpdatingRef(tag);

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const onDropImages = (acceptedFiles) => {
    setImages(acceptedFiles);
  };
  const onDropMarkdown = (acceptedFiles) => {
    setMarkdownFiles(acceptedFiles);
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept: '	application/zip, application/x-gzip',
    onDrop
  });

  useDropzone({
    accept: 'image/png, image/jpg, image/jpeg, image/gif',
    onDrop: onDropImages
  });

  useDropzone({
    accept: '',
    onDrop: onDropMarkdown
  });

  const thumbs = files.map((file) => (
    <li key={file.name}>
      {truncate(file.name, {
        length: 24
      })}
    </li>
  ));

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setFetcher(() => {
        let formData = new FormData();
        // @todo use all for testing, use user selected.
        // const analytics = availableAnalytics.map(({name}) => name).join(',');
        if (collectionIds.length) {
          formData.append('analytics', collectionIds.join(','));
        }
        if (agIds.length) {
          formData.append('existing_ags', agIds.join(','));
        }

        imagesRef.current.forEach((f, i) => {
          formData.append(`image[]`, f, f.name);
        });

        // splits on new lines, commas + newlines
        // could be improved and unit tested :)
        const parsedUrls = compact(urlsRef.current.replace(/,.*\n/g, '\n').split(/\r?\n/));
        if (parsedUrls.length) {
          formData.append('urls', parsedUrls.join(','));
        }
        formData.append('tag', tagRef.current);
        formData.append('url', urlRef.current);
        markdownFilesRef.current.forEach((f, i) => {
          formData.append(`markdown[]`, f, f.name);
        });
        filesRef.current.forEach((f, i) => {
          formData.append(`file[]`, f, f.name);
        });
        return fetch(`${REACT_APP_BACKEND_PYTHON_ENDPOINT}/test`, {
          body: formData,
          // headers: {
          //   'Content-Type': 'multipart/form-data'
          // },
          method: 'POST',
          mode: 'no-cors'
        })
          .then((res) => res.text())
          .then((data) => {
            console.log('data', data);
          })
          .catch((err) => {
            console.log('err', err);
          });
      });
      setFiles([]);
      setImages([]);
      setMarkdownFiles([]);
      setUrls('');
      setUrl('');
    },
    [agIds, collectionIds, filesRef, imagesRef, markdownFilesRef, tagRef, urlRef, urlsRef]
  );

  // @todo update to use mutation.mutate
  // https://react-query.tanstack.com/guides/mutations
  useMutation(fetcher);

  const classes = useStyles();
  return useMemo(
    () => (
      <Grid container>
        <Grid item xs={3}>
          <Paper className={classes.paperLeft}>
            <div className={classes.helpButton}>
              <HelpButton
                size="small"
                url="/docs/visualize-and-validate/how-it-works"
                title="Ingest Help"
              />
            </div>
            <Box p={2}>
              <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <FormGroup>
                  <TextField
                    id="tag-input"
                    label="Tag"
                    placeholder="test-0"
                    helperText="Tag to group this request"
                    fullWidth
                    defaultValue={tag}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    onInput={(e) => {
                      setTag(e.target.value);
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <Typography>Ingest from AOM File</Typography>
                  <section className="container">
                    <div {...getRootProps({ className: classes.dropzone })}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                      <ul>{thumbs}</ul>
                    </div>
                    {/* <aside style={thumbsContainer}>
                    <ul>{thumbs}</ul>
                  </aside> */}
                  </section>
                </FormGroup>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!url && !files.length && !images.length && !agIds.length}
                >
                  Ingest
                </Button>
              </form>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <AvailableAnalytics />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <ArtifactGraphTable whichRoute="transition-ui" tag={tag} />
          </Paper>
        </Grid>
        {/* <Grid item xs={4}>
          <Paper className={classes.paper}>
          <AgDetail />
          </Paper>
        </Grid> */}
        {/* <Grid item xs={4}>
          <Paper className={classes.paper}>
            <AgDetail />
          </Paper>
        </Grid> */}
      </Grid>
    ),
    [
      classes.paperLeft,
      classes.helpButton,
      classes.root,
      classes.dropzone,
      classes.paper,
      handleSubmit,
      tag,
      getRootProps,
      getInputProps,
      thumbs,
      url,
      files.length,
      images.length,
      agIds.length
    ]
  );
};

export default IngestAOMs;
