/* eslint-disable sort-keys-fix/sort-keys-fix */
import Box from '@material-ui/core/Box';
import { AxisBottom } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarStack } from '@visx/shape';
import Cytoscape from 'cytoscape';
import { chain, filter, get, reduce, size } from 'lodash';
import React, { useMemo, useState } from 'react';

import checkNodeIdsForEdgesExist from '../../helpers/checkNodeIdsForEdgesExist';
import formatEdgesForGraph from '../../helpers/formatEdgesForGraph';
import formatNodesForGraph from '../../helpers/formatNodesForGraph';
import LLRGauge from '../LLRGauge';
import nodeType from '../NodeType';

// @todo display image / text inconsistency
// aomLoc is json stringified start and stop
// ex: http://localhost:3001/gallery/a5b608dce8683924c8bd1e6f7ccf1d0cd16f65fd04b93ac96ca480a3097d2fb9
//

// eslint-disable-next-line no-unused-vars
function round(num) {
  if (num !== undefined && num !== null && num.toFixed) {
    return num.toFixed(2);
  }
}

function formatFromCyNode(cyNode) {
  let label = '';
  const originalNode = cyNode.data('originalNode');
  if (!nodeType[originalNode.nodeType]) {
    console.warn(`Missing nodeType formatter for ${originalNode.nodeType}`);
  } else {
    label = nodeType[originalNode.nodeType](originalNode);
  }
  return label;
}

const ScoreViewerSimpleDots = (props) => {
  const [sortBy] = useState('EvDetectionNode');
  const [sortDir] = useState('desc');
  const { agId, agNamespace, nodes = [], edges = [], showAg = true, associatedGraphs } = props;

  // @TODO DRY ======== begin stuff to DRY into helpers

  // @TODO check for valid data
  const hasAnyData = size(nodes) > 0 || size(edges) > 0;

  const fakeNodes = [];
  const fakeEdges = [];

  // add fake nodes for associated Graphs
  if (showAg && associatedGraphs) {
    nodes.forEach(({ graphId, id: _id, nodeId, nodeType }) => {
      if (nodeType === 'EvReferenceNode') {
        const targetName = get(associatedGraphs, [graphId, 'name'], null);
        if (!targetName) {
          // @todo graph is invalid
          // isValid.valid = false;
          // isValid.errors.push(`associatedGraphs.${graphId} does not exist.`);
        } else {
          // @todo here!
          // @todo remove this check
          if (nodeId === '2984603c-0437-4250-be76-b8b3d917ecd0') {
            nodeId = '0';
          }
          if (nodeId !== undefined) {
            const newEdge = {
              edgeType: '',
              source: _id,
              target: `${targetName}-${nodeId}`
            };
            fakeEdges.push(newEdge);
          } else {
            const newEdge = {
              // @todo replace with actual rootNodeId
              edgeType: '',

              source: _id,
              target: `${targetName}-${0}`
            };
            fakeEdges.push(newEdge);
          }
        }
      }
    });
  }

  // * add originalNode / link
  // * add some basic style props
  let _nodes = formatNodesForGraph([...nodes, ...fakeNodes]);
  let _edges = formatEdgesForGraph([...edges, ...fakeEdges]);

  // @todo optimize this, move outta here, into helper maybe? Use reduce maybe?
  const els = useMemo(() => {
    let ret = [];
    _nodes.forEach((node) => {
      ret.push({
        data: {
          ...node
        }
      });
    });
    _edges.forEach((edge) => {
      ret.push({
        data: {
          ...edge
        }
      });
    });
    return ret;
  }, [_nodes, _edges]);

  const isValid = checkNodeIdsForEdgesExist(_nodes, _edges);

  // ======== END @TODO DRY

  return useMemo(() => {
    if (!hasAnyData) return 'No graph data.';
    if (isValid.valid !== true) {
      return (
        <>
          <span>Invalid graph data:&nbsp;</span>
          <ul>
            {isValid.errors.map((error, idx) => (
              <li key={idx}>{error}&nbsp;</li>
            ))}
          </ul>
        </>
      );
    }

    const cy = new Cytoscape({
      elements: els,
      headless: true,
      styleEnabled: false
    });

    // + is ele1 after ele2
    const analytics = cy.nodes('[nodeType = "EvAnalysisNode"]').sort((ele1, ele2) => {
      const ele1Score = ele1.outgoers(`node[nodeType = "${sortBy}"]`);
      const ele2Score = ele2.outgoers(`node[nodeType = "${sortBy}"]`);
      // neither score
      if (!ele1Score[0] && !ele2Score[0]) {
        return -1;
      }
      if (!ele1Score[0]) {
        return 1;
      }
      if (!ele2Score[0]) {
        return -1;
      }
      const ele1LLR = ele1Score[0].data('originalNode').score.score;
      const ele2LLR = ele2Score[0].data('originalNode').score.score;
      if (ele1LLR === ele2LLR) {
        return 0;
      }
      if (ele1LLR > ele2LLR) {
        return sortDir === 'desc' ? -1 : 1;
      }
      return sortDir === 'desc' ? 1 : -1;
    });

    const aRows = analytics.map((a) => AnalyticRow({ a }));

    const tallies = {
      dScore: {
        red: 0,
        orange: 0,
        white: 0,
        green: 0
      },
      aScore: {
        red: 0,
        orange: 0,
        white: 0,
        green: 0
      },
      cScore: {
        red: 0,
        orange: 0,
        white: 0,
        green: 0
      }
    };

    aRows.forEach((r) => {
      tallies.dScore.green += filter(r.dScore, (_r) => _r === 'green').length;
      tallies.dScore.orange += filter(r.dScore, (_r) => _r === 'orange').length;
      tallies.dScore.red += filter(r.dScore, (_r) => _r === 'red').length;
      tallies.dScore.white += filter(r.dScore, (_r) => _r === 'white').length;

      tallies.aScore.green += filter(r.aScore, (_r) => _r === 'green').length;
      tallies.aScore.orange += filter(r.aScore, (_r) => _r === 'orange').length;
      tallies.aScore.red += filter(r.aScore, (_r) => _r === 'red').length;
      tallies.aScore.white += filter(r.aScore, (_r) => _r === 'white').length;

      tallies.cScore.green += filter(r.cScore, (_r) => _r === 'green').length;
      tallies.cScore.orange += filter(r.cScore, (_r) => _r === 'orange').length;
      tallies.cScore.red += filter(r.cScore, (_r) => _r === 'red').length;
      tallies.cScore.white += filter(r.cScore, (_r) => _r === 'white').length;
    });

    if (agId === '643d788c71b81277555b90a2fe49239a512ffbb2f3155a5f7921f162079ab506') {
      console.log(aRows);
      console.log({
        agId,
        agNamespace,
        tallies
      });
    }

    const formatted = [];
    Object.keys(tallies).forEach((key) => {
      formatted.push({
        x: key,
        ...tallies[key]
      });
    });

    const maxValue = formatted.reduce((max, { green, orange, red, white }) => {
      const thisTotal = green + orange + red + white;
      return Math.max(thisTotal, max);
    }, 0);

    const width = 80;
    const height = 80;
    const margin = { top: 3, right: 0, bottom: 0, left: 0 };

    const xScale = scaleBand({
      domain: ['dScore', 'aScore', 'cScore'],
      range: [0, 80, 160],
      padding: 0.1
    });
    const yScale = scaleLinear({
      domain: [maxValue, 0],
      range: [0, height - 20]
    });

    const colorScale = scaleOrdinal({
      domain: ['green', 'orange', 'red', 'white'],
      range: ['green', 'orange', 'red', 'white']
    });

    return (
      <div style={{ position: 'relative', marginTop: 10, display: 'flex' }}>
        <svg width={width} height={height}>
          <Group top={margin.top}>
            <BarStack
              keys={['green', 'orange', 'red', 'white']}
              data={formatted}
              x={(d) => {
                return d.x;
              }}
              // y0={(props) => {
              //   return props[0];
              // }}
              color={colorScale}
              xScale={xScale}
              yScale={yScale}
              order="ascending"
            >
              {(barStacks) => {
                return barStacks.map((barStack) =>
                  barStack.bars.map((bar) => {
                    return (
                      <rect
                        key={`bar-stack-${barStack.index}-${bar.index}`}
                        x={bar.x}
                        y={bar.y}
                        height={bar.height}
                        width={bar.width}
                        fill={bar.color}
                        opacity={1}
                      />
                    );
                  })
                );
              }}
            </BarStack>
          </Group>
          <AxisBottom
            top={height - 28}
            scale={xScale}
            tickFormat={(a) => {
              return a.replace('Score', '');
            }}
            hideTicks
            hideAxisLine
            stroke={'black'}
            // tickStroke={purple3}
            // tickLabelProps={() => ({
            //   fill: purple3,
            //   fontSize: 11,
            //   textAnchor: "middle"
            // })}
          />
        </svg>
      </div>
    );
  }, [agId, agNamespace, els, hasAnyData, isValid.errors, isValid.valid, sortBy, sortDir]);
};

function AnalyticRow(props) {
  const { a } = props;
  const dScore = a.outgoers('node[nodeType = "EvDetectionNode"]');
  const aScore = a.outgoers('node[nodeType = "EvAttributionNode"]');
  const cScore = a.outgoers('node[nodeType = "EvCharacterizationNode"]');

  const scale = {
    red: {
      high: Infinity,
      low: 0.5000000000000000001
    },
    orange: {
      high: 0.5,
      low: 0.2000000000000000001
    },
    white: {
      high: 0.2,
      low: -0.5
    },
    green: {
      high: -0.5000000000000000001,
      low: -Infinity
    }
  };

  function getTextFromScore(score) {
    return chain(scale).pickBy(textHasScoreRange).keys().value();

    function textHasScoreRange(rangeObj) {
      return rangeObj.low <= score && rangeObj.high >= score;
    }
  }

  const scores = {
    dScore: getTextFromScore(
      dScore.map((s) => {
        return s.length > 0 ? s[0].data('originalNode').score.score : 0;
      })
    ),
    aScore: getTextFromScore(
      aScore.map((s) => {
        return s.length > 0 ? s[0].data('originalNode').score.score : 0;
      })
    ),
    cScore: getTextFromScore(
      cScore.map((s) => {
        return s.length > 0 ? s[0].data('originalNode').score.score : 0;
      })
    )
  };

  return scores;
}

const types = {
  EvAttributionNode: 'Attribution',
  EvCharacterizationNode: 'Characterization',
  EvDetectionNode: 'Detection'
};

// eslint-disable-next-line no-unused-vars
function HypothesisSummary({ score }) {
  const checks = score.successors(`node[nodeType = "EvConsistencyCheckNode"]`);
  const { nodeType } = score.data('originalNode');
  const displayType = types[nodeType];
  return (
    <Box
      p={3}
      bgcolor="background.default"
      borderColor="action.disabled"
      borderRadius={3}
      border="1px solid"
      marginBottom={3}
    >
      <h4 style={{ margin: 0 }}>{displayType}</h4>
      <ul>
        {checks.map((check) => {
          const evidenceNodes = check.successors(`node[nodeType = "EvConceptNode"]`);
          // this MMA has a ${type} inconsistency evidenced by ${evidence}
          const evidence = reduce(
            evidenceNodes,
            (acc, e) => {
              return [...acc, e.data('originalNode').label];
            },
            []
          );
          const { category, score } = check.data('originalNode');
          const statement = `"this MMA has a ${category.toLowerCase()} ${score.scoreType} ${
            score.score
          } inconsistency evidenced by ${evidence.join(', ')}"`;
          return (
            <li key={check.data('id') || check}>
              <strong>
                <LLRGauge node={check.data('originalNode')} />
              </strong>{' '}
              {statement}
            </li>
          );
        })}
      </ul>
    </Box>
  );
}

// eslint-disable-next-line no-unused-vars
function Checks(props) {
  const { checks } = props;
  return (
    <React.Fragment>
      <span>Evidenced by Consistency Checks:</span>
      <br />
      <ul>
        {checks.map((check) => {
          const evidence = check.successors(`node[nodeType = "EvConceptNode"]`);
          return (
            <li key={check.data('id') || check}>
              {formatFromCyNode(check)}
              {/* <LLRGauge node={check.data('originalNode')} /> */}
              <br />
              <span>Evidence Concepts:</span>
              <br />
              <ul>
                {evidence.map((e) => {
                  const aomRefsLocation = e
                    .connectedEdges('edge[edgeType = "LocationInAsset"]')
                    .connectedNodes('node[nodeType = "EvAomLocIdNode"]');
                  const aomRefsNoLocation = e
                    .connectedEdges('edge[edgeType = "InAsset"]')
                    .connectedNodes('node[nodeType = "EvReferenceNode"]');
                  return (
                    <li key={e.data('id') || e}>
                      {formatFromCyNode(e)}
                      <LLRGauge node={e.data('originalNode')} />
                      {}
                      {aomRefsNoLocation.length && (
                        <>
                          <br />
                          <span>EvReferenceNodes (No location specified)</span>
                          <br />
                        </>
                      )}
                      <ul>
                        {/* {aomRefsNoLocation.map((refNode) => {
                          // should only be one
                          // @todo could also give our "FAKE" edge a better name and we could use to get this
                          const theImg = refNode.outgoers('node');
                          let uri = theImg.data('originalNode').assetDataUri || '';
                          let ret = 'uri missing';
                          if (uri) {
                            uri = replaceMinioEndpoint(uri);
                            const type = mimeTypes.lookup(uri);
                            if (type.indexOf('image/') === 0) {
                              ret = <img width="200" alt="article" src={uri} />;
                            } else if (type.indexOf('application/json') === 0) {
                              ret = (
                                <a href={uri} target="_blank" rel="noreferrer">
                                  <DescriptionIcon /> Entire AOM
                                </a>
                              );
                            } else {
                              ret = (
                                <p style={{ color: 'red' }}>Reference {type} not yet supported</p>
                              );
                            }
                          }
                          return (
                            <React.Fragment key={refNode.data('id') || uri}>{ret}</React.Fragment>
                          );
                        })} */}
                      </ul>
                      {aomRefsLocation.length > 0 && (
                        <>
                          <br />
                          <span>EvReferenceNodes (Specific location)</span>
                          <br />
                        </>
                      )}
                      <ul>
                        {aomRefsLocation.map((refNode) => {
                          let loc;
                          const { aomLoc } = refNode.data('originalNode');
                          // it might be json
                          try {
                            loc = JSON.parse(aomLoc);
                          } catch (e) {
                            loc = aomLoc;
                          }
                          return <pre key={refNode.data('id')}>{JSON.stringify(loc, null, 2)}</pre>;
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
}

export default ScoreViewerSimpleDots;
