import PropTypes from 'prop-types';
import React, { createContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

export const TagContext = createContext({});

export function TagContextProvider({ children }) {
  const [tag, setTag, removeTag] = useLocalStorage('tag', '');

  return useMemo(
    () => (
      <TagContext.Provider
        value={{
          removeTag,
          setTag,
          tag
        }}
      >
        {children}
      </TagContext.Provider>
    ),
    [tag, setTag, removeTag, children]
  );
}

TagContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
