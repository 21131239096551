import EvScore from './EvScore';

const Node = (props) => {
  return `
${props.nodeType}
${EvScore(props.score)}
  `;
  // annotations
  // score
};

export default Node;
