import PropertyAnnotations from './PropertyAnnotations';

const Node = (props) => {
  return `
${props.nodeType}
${PropertyAnnotations(props)}
  `;
  // aomLoc
};

export default Node;
