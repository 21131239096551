import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { get, reduce } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';

import { SettingsContext } from '../../../contexts/SettingsContext';
import { TokenContext } from '../../../contexts/TokenContext';
import safeParseApiJson from '../../../helpers/safeParseApiJson';
import GraphViewCtyo from '../../GraphViewCtyo';
import LoadEg from '../../LoadEg';

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2)
  },
  container: {
    maxHeight: '100%'
  },
  table: {
    width: '100%'
  },
  tableRow: {
    cursor: 'pointer',
    transition: theme.transitions.create('backgroundColor', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.sharp
    })
  }
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

const WorkflowsForAgTable = (props) => {
  const classes = useStyles();
  const [count, setCount] = useState(Infinity);
  const [page, setPage] = useState(0);
  const { connection } = useContext(SettingsContext);
  const { token } = useContext(TokenContext.Dynamic);
  const pageSize = 100;
  const { agId, agNamespace } = props;

  const filter = {
    'spec.artifactGraph.name': { $eq: agId },
    'spec.artifactGraph.namespace': { $eq: agNamespace }
  };
  const fetchFn = ({ pageParam = undefined }) =>
    fetch(
      `${connection}/analyticworkflows?filter=${JSON.stringify(
        filter
      )}&limit=${pageSize}&continueToken=${pageParam ? pageParam : ''}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
      .then((res) => res.text())
      .then(safeParseApiJson);

  const { data, error, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
    ['analyticworkflows', agId, agNamespace],
    fetchFn,
    {
      getNextPageParam: (lastPage) => get(lastPage, 'metadata.continueToken'),
      refetchInterval: false
    }
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (isFetching) {
        console.warn('Wait until current request is complete to load next page');
        return;
      }
      if (newPage > page && hasNextPage) {
        fetchNextPage().then(() => {
          setPage(newPage);
        });
      } else {
        setPage(newPage);
      }
    },
    [fetchNextPage, page, hasNextPage, isFetching]
  );

  // @todo implement in AG Graph Table if this performs
  useEffect(() => {
    if (hasNextPage === false) {
      const totalCount = reduce(data.pages, (acc, page) => acc + page.items.length, 0);
      setCount(totalCount);
    }
  }, [data, hasNextPage]);

  return useMemo(
    () => (
      <>
        {error && 'An error has occurred: ' + error.message}
        <Button
          disabled
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddCircleIcon />}
        >
          New Workflow For MMA
        </Button>
        {!error && data && data.pages && data.pages.length > 0 && (
          <TableContainer component={Paper} className={classes.container}>
            <Table size="small" aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[pageSize]}
                    colSpan={8}
                    count={count} // @todo get from api once added
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={handleChangePage}
                  />
                </TableRow>
                <TableRow>
                  <TableCell width="400">Name</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell>Phase Count</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Reason</TableCell>
                  {/* <TableCell>Spec</TableCell> */}
                  <TableCell align="right">Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {get(data, ['pages', page, 'items'], []).map((row) => (
                  <Row
                    row={row}
                    workflowName={get(row, 'metadata.name')}
                    key={get(row, 'metadata.name')}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    ),
    [error, data, classes.container, classes.button, count, page, handleChangePage]
  );
};

function Row(props) {
  const { row, workflowName } = props;
  const classes = useRowStyles();
  const history = useHistory();
  let { agId, agNamespace, workflowId: routeWorkflowId } = useParams();
  const [open, setOpen] = React.useState(workflowName === routeWorkflowId);
  // @todo support namespace here and in route
  // const handleRowClick = useCallback(
  //   (workflowId) => {
  //     history.push(`/workflows/${workflowId}`);
  //   },
  //   [history]
  // );
  const handleSetOpen = useCallback(
    (workflowId) => {
      if (!open) {
        history.push(`/gallery/${agNamespace}/${agId}/5/${workflowId}`);
      } else {
        history.push(`/gallery/${agNamespace}/${agId}/5`);
      }
      setOpen(!open);
    },
    [agId, agNamespace, history, open]
  );
  const responseActionTypes = reduce(
    get(row, 'status.phases', []),
    (acc, phase) => {
      const componentsRecords = get(phase, 'componentsRecords', []);
      const types = reduce(
        componentsRecords,
        (acc, { responseActionTypes = {} }) => {
          return [...acc, ...Object.keys(responseActionTypes)];
        },
        []
      );
      return [...acc, ...types];
    },
    []
  );
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {get(row, 'metadata.name')}
        </TableCell>
        <TableCell>{get(row, 'metadata.creationTimestamp')}</TableCell>
        <TableCell>{get(row, 'status.phases.length')}</TableCell>
        <TableCell>{responseActionTypes.join(', ')}</TableCell>
        <TableCell>{get(row, 'status.state')}</TableCell>
        <TableCell>{get(row, 'status.reason')}</TableCell>
        {/* <TableCell>
          <IconButton size="small" onClick={() => handleRowClick(get(row, 'metadata.name'))}>
            <BuildIcon fontSize="small" />
          </IconButton>
        </TableCell> */}
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleSetOpen(get(row, 'metadata.name'))}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {/* <TableRow key={`${get(row, 'metadata.name')}-analytics`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Box>
              {get(row, 'status.phases', []).map((phase) => {
                return (
                  <React.Fragment key={phase.name}>
                      {phase.componentsRecords.map((record) => {
                        return (
                          <React.Fragment key={record.name}>
                            {record.analyticOutput && record.analyticOutput.evigraphRef && (
                              <div style={{ position: 'relative' }}>
                                <LoadEg
                                  renderComponent={ScoreViewer}
                                  name={record.analyticOutput.evigraphRef.name}
                                  namespace={record.analyticOutput.evigraphRef.namespace}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </Box>
        </TableCell>
      </TableRow> */}
      <TableRow key={`${get(row, 'metadata.name')}-phases`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              {/* <Typography variant="h6" gutterBottom component="div">
                Spec
              </Typography>
              <WorkflowForm name={get(row, 'metadata.name')} /> */}
              <Typography variant="h6" gutterBottom component="div">
                Results
              </Typography>
              {get(row, 'status.phases', []).map((phase) => {
                return (
                  <React.Fragment key={phase.name}>
                    <Typography variant="h6" gutterBottom component="div">
                      {phase.name}: {phase.state}
                    </Typography>
                    <Typography variant="subtitle" gutterBottom component="div">
                      Phase Records
                    </Typography>
                    <ul>
                      {phase.componentsRecords.map((record) => {
                        return (
                          <li key={record.name}>
                            <p>
                              {record.name}: {record.state}: {record.reason}
                            </p>
                            {record.analyticOutput && record.analyticOutput.evigraphRef && (
                              <div style={{ position: 'relative' }}>
                                <LoadEg
                                  renderComponent={GraphViewCtyo}
                                  name={record.analyticOutput.evigraphRef.name}
                                  namespace={record.analyticOutput.evigraphRef.namespace}
                                />
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </React.Fragment>
                );
              })}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default WorkflowsForAgTable;
