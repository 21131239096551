import { makeStyles, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { get, isInteger } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { SettingsContext } from '../../contexts/SettingsContext';
import { TokenContext } from '../../contexts/TokenContext';
import safeParseApiJson from '../../helpers/safeParseApiJson';
import AomViewer from '../AomViewer/AomViewer';
import AvailableAnalytics from '../AvailableAnalytics';
import GraphViewCtyo from '../GraphViewCtyo';
import HelpButton from '../HelpButton/HelpButton';
import LoadEgsForAg from '../LoadEgsForAg/LoadEgsForAg';
import LoadEgsForAgSomeAnalytics from '../LoadEgsForAg/LoadEgsForAgSomeAnalytics';
import ScoreViewer from '../ScoreViewer';
import WorkflowsForAgTable from '../tables/WorkflowsForAgTable';
import LoadAOMForAg from './../LoadAOMForAg';

const useStyles = makeStyles((theme) => {
  const height = '100%';
  return {
    graph: {
      height,
      marginLeft: theme.spacing(2),
      overflow: 'auto',
      position: 'relative'
    },
    graphBox: {
      height,
      position: 'relative',
      verticalAlign: 'center'
    },
    paper: {
      height,
      overflow: 'auto',
      position: 'relative'
    },
    tabPanel: {
      height: 'calc(100% - 73px)',
      left: 0,
      position: 'absolute',
      width: '100%'
    },
    tabs: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.2)'
    }
  };
});

function PickOne() {
  return (
    <div
      style={{
        marginTop: '25vh',
        textAlign: 'center',
        width: '100%'
      }}
    >
      <h2>Ingest media and select from left column to get started</h2>
      <ArrowBackIcon />
    </div>
  );
}

function TabPanel(props) {
  const { children, index, value, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const AgDetail = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const { connection } = useContext(SettingsContext);
  const { token } = useContext(TokenContext.Dynamic);
  let { agId, agNamespace, tabIndex } = useParams();
  const [value, setValue] = useState(parseInt(tabIndex || 0, 10));
  useEffect(() => {
    const _newIndex = parseInt(tabIndex, 10);
    if (isInteger(_newIndex)) {
      setValue(_newIndex);
    } else {
      setValue(11);
    }
  }, [agId, tabIndex]);
  const handleChange = useCallback(
    (event, newValue) => {
      if (newValue !== value) {
        setValue(newValue);
      }
    },
    [value]
  );
  useEffect(() => {
    // history.push(`/analytics-runner/${agId}/${value}`);
  }, [value, agId, history]);
  const fetch0 = () => {
    const filter = { 'metadata.name': { $eq: agId }, 'metadata.namespace': { $eq: agNamespace } };
    return fetch(`${connection}/artifactgraphs?filter=${JSON.stringify(filter)}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((res) => res.text())
      .then(safeParseApiJson);
  };

  const { data, isFetching } = useQuery([agId, agNamespace], fetch0, { keepPreviousData: false });

  const ag = get(data, 'items[0]');

  return useMemo(
    () => (
      <>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          style={{
            borderBottom: `1px solid ${theme.palette.action.disabledBackground}`
          }}
        >
          <Tab
            value={11}
            label="Artifact Graph"
            icon={
              <HelpButton
                asIcon
                size="small"
                title="What is this?"
                url="/docs/appserver/mma-details/aom-viewer"
              />
            }
          />
          <Tab
            value={0}
            label="AOM Viewer"
            icon={
              <HelpButton
                asIcon
                size="small"
                title="What is this?"
                url="/docs/appserver/mma-details/aom-viewer"
              />
            }
          />
          {/* <Tab
            value={9}
            label="Favorite Analytics"
            icon={
              <HelpButton
                asIcon
                size="small"
                title="What is this?"
                url="/docs/appserver/mma-details/aom-viewer"
              />
            }
          /> */}
          {/* <Tab value={1} label="AOM Metadata" icon={<HelpButton asIcon size="small" title="What is this?" url="/docs/appserver/mma-details/metadata" />} /> */}
          <Tab
            value={5}
            label="Analytic Workflows"
            icon={
              <HelpButton
                asIcon
                size="small"
                title="What is this?"
                url="/docs/appserver/mma-details/workflows"
              />
            }
          />
          <Tab
            value={2}
            label="Score Viewer"
            icon={
              <HelpButton
                asIcon
                size="small"
                title="What is this?"
                url="/docs/appserver/mma-details/score-viewer"
              />
            }
          />
          <Tab
            value={3}
            label="Explanation Graph"
            icon={
              <HelpButton
                asIcon
                size="small"
                title="What is this?"
                url="/docs/appserver/mma-details/explanation-graph"
              />
            }
          />
        </Tabs>
        {!ag && <PickOne />}
        {ag && (
          <>
            <TabPanel value={value} index={9}>
              <AvailableAnalytics />
            </TabPanel>
            <TabPanel value={value} index={0}>
              <Box p={3}>
                <LoadAOMForAg ag={ag} renderComponent={AomViewer} />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box p={3}>
                <LoadEgsForAg
                  agId={agId}
                  agNamespace={agNamespace}
                  ag={ag}
                  renderComponent={ScoreViewer}
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <LoadEgsForAgSomeAnalytics
                agId={agId}
                agNamespace={agNamespace}
                ag={ag}
                renderComponent={(props) => <GraphViewCtyo full {...props} />}
              />
            </TabPanel>
            <TabPanel value={value} index={11}>
              {ag && !isFetching && (
                <GraphViewCtyo
                  full
                  ag={ag}
                  id={agId}
                  agNamespace={agNamespace}
                  nodes={ag.nodes}
                  edges={ag.edges}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Box p={3}>
                <WorkflowsForAgTable agId={agId} agNamespace={agNamespace} ag={ag} />
              </Box>
            </TabPanel>
          </>
        )}
      </>
    ),
    [
      ag,
      agId,
      agNamespace,
      handleChange,
      isFetching,
      theme.palette.action.disabledBackground,
      value
    ]
  );
};

export default AgDetail;
