import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import React, { useCallback, useMemo } from 'react';

import HasAuthRole from '../HasAuthRole';

const { REACT_APP_DOCS_ENDPOINT } = process.env;

const HelpButton = (props) => {
  const { asIcon = false, size = 'default', title = 'Help', url = '/docs' } = props;
  const openHelp = useCallback(() => {
    window.open(`${REACT_APP_DOCS_ENDPOINT}${url}`);
  }, [url]);
  return useMemo(
    () => (
      <HasAuthRole allRoles={["hmi-access-help"]}>
        {asIcon && <HelpIcon onClick={() => openHelp()} fontSize={size} />}
        {!asIcon && (
          <IconButton
            size={size === 'default' ? 'medium' : 'small'}
            title={title}
            aria-label="help"
            color="inherit"
            onClick={() => openHelp()}
          >
            <HelpIcon fontSize={size} />
          </IconButton>
        )}
      </HasAuthRole>
    ),
    [asIcon, openHelp, size, title]
  );
};

export default HelpButton;
