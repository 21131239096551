import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useMemo, useState } from "react";

import { SettingsContext } from "../../contexts/SettingsContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
    overflow: "auto",
    position: "relative",
  },
}));

const ConnectionSelector = (props) => {
  const {
    clientId,
    connection,
    defaultClientId,
    defaultConnection,
    reset,
    setClientId,
    setConnection,
  } = useContext(SettingsContext);
  const [_connection, _setConnection] = useState(connection);
  const [_clientId, _setClientId] = useState(clientId);
  const { keycloak } = useKeycloak();
  const classes = useStyles();
  return useMemo(
    () => (
      <Box p={3} textAlign="center">
        <TextField
          id="outlined-basic"
          label="App Server URI"
          variant="outlined"
          value={_connection}
          fullWidth
          onChange={(event) => {
            _setConnection(event.target.value);
          }}
        />
        <br />
        <br />
        <TextField
          id="outlined-basic"
          label="Client Id"
          variant="outlined"
          value={_clientId}
          fullWidth
          onChange={(event) => {
            _setClientId(event.target.value);
          }}
        />
        <br />
        <br />
        <Button
          disabled={_clientId === clientId && _connection === connection}
          variant="contained"
          color="primary"
          onClick={() => {
            keycloak.logout();
            setClientId(_clientId);
            setConnection(_connection);
          }}
        >
          Sign out &amp; Update
        </Button>
        <br />
        <br />
        <Button
          disabled={clientId === defaultClientId || connection === defaultConnection}
          variant="contained"
          color="primary"
          onClick={() => {
            keycloak.logout();
            reset();
            _setClientId(defaultClientId);
            _setConnection(defaultConnection);
          }}
        >
          Sign out &amp; Reset
        </Button>
      </Box>
    ),
    [_clientId, clientId, _connection, connection, defaultClientId, defaultConnection, keycloak, reset, setClientId, setConnection]
  );
};

export default ConnectionSelector;
