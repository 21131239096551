import { IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { SettingsContext } from '../../contexts/SettingsContext';
import { TokenContext } from '../../contexts/TokenContext';

const AppServerStatus = (props) => {
  const { connection, dialogToggle } = useContext(SettingsContext);
  const { token } = useContext(TokenContext.Dynamic);
  const { refetchInterval = 20000 } = props;
  const history = useHistory();
  const { data, error } = useQuery(
    ['appServerStatus', token, connection],
    () => fetch(`${connection}/artifactgraphs?limit=0`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }),
    { refetchInterval }
  );
  // let icon = <OfflineBoltIcon />;
  const isConnected = !error && data && (data.status === 200 || data.status === 401);
  // if (isConnected) {
  //   icon = <LinkIcon />;
  // }
  return (
    <div>
      <>
        SemaFor API: {!connection && 'Not Connected'}
        {isConnected && `OK`}
        {!isConnected && 'Error'}
      </>
      <IconButton
        onClick={() => {
          dialogToggle(true);
        }}
        variant="outlined"
        style={{ borderColor: 'white', color: 'white', marginLeft: 10 }}
      >
        <SettingsIcon />
      </IconButton>
    </div>
  );
};

export default AppServerStatus;
