import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import React, { useContext } from "react";

import menus from '../../constants/menus';
import { drawerWidth } from '../../constants/navigation';
import { NavDrawerContext } from '../../contexts/NavDrawerContext';
import HelpButton from '../HelpButton';
import MenuItem from './MenuItem';

const useStyles = makeStyles((theme) => ({
  drawerClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    }),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7)
    }
  },
  drawerOpen: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp
    }),
    width: drawerWidth
  },
  toolbar: {
    ...theme.mixins.toolbar,
    alignItems: 'center',
    display: 'flex',
    position: 'relative'
  }
}));

const NavDrawer = (props) => {
  const { open, setOpen } = useContext(NavDrawerContext);
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      className={clsx({
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <IconButton onClick={() => {
          setOpen(false);
        }}>
          <ChevronLeftIcon />
        </IconButton>
        <div>
          <HelpButton
            url="/docs/welcome/navigation"
            title="Need help choosing where to navigate?"
          />
        </div>
      </div>
      <Divider />
      <div className={classes.menuPanel}>
        {menus.map((menu) => (
          <React.Fragment key={menu.name}>
            <List
              subheader={
                open ? <ListSubheader>{menu.name}</ListSubheader> : null
              }
            >
              {menu.subMenu.map((menuItem) => {
                return (
                  menuItem.nav.menu && (
                    <MenuItem
                      menuItemObj={menuItem}
                      open={open}
                      key={menuItem.name}
                    />
                  )
                );
              })}
            </List>
            <Divider />
          </React.Fragment>
        ))}
      </div>
    </Drawer>
  );
};

export default NavDrawer;
