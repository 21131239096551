import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { sortBy } from 'lodash';
import React from 'react';

import { CompetitionContext } from '../../contexts/CompetitionContext';
import competitions from './../../constants/gym-competitions.json';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const CompetitionSelect = (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setCompetition(event.target.value);
  };
  const { competition, setCompetition } = React.useContext(CompetitionContext);

  // @todo when namespace changes, set competition

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Filter by competition</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        key={competition}
        native
        value={competition}
        onChange={handleChange}
        variant="outlined"
      >
        <option aria-label="None" value="" key="empty" />
        {sortBy(competitions, 'id').map((c) => (
          <option key={c.id} value={c.id}>
            {c.id}: {c.metadata.labels.map((l) => `[${l}] `)}
            {c.metadata.title}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default CompetitionSelect;
