import { Box, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useCallback, useState } from 'react';
import { RecoilRoot } from 'recoil';

import HelpButton from '../HelpButton';
import FileDropper from './../FileDropper';
import GraphFromRecoilState from './../GraphFromRecoilState';
import GraphValidatorFromRecoilState from './../GraphValidatorFromRecoilState';

const useStyles = makeStyles((theme) => {
  const height = '100%';
  return {
    graph: {
      height,
      marginLeft: theme.spacing(2),
      overflow: 'auto',
      position: 'relative'
    },
    graphBox: {
      height,
      position: 'relative',
      verticalAlign: 'center'
    },
    paper: {
      height,
      overflow: 'auto',
      position: 'relative'
    },
    tabPanel: {
      height: 'calc(100% - 73px)',
      left: 0,
      position: 'absolute',
      width: '100%'
    },
    tabs: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.2)'
    }
  };
});

function TabPanel(props) {
  const { children, index, value, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      style={{
        visibility: value === index ? 'visible' : 'hidden'
      }}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

const DragAndDropVisualizer = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = useCallback(
    (event, newValue) => {
      if (newValue !== value) {
        setValue(newValue);
      }
    },
    [value]
  );
  return (
    <RecoilRoot>
      <Grid container>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Box p={3} className={classes.graphBox}>
              <FileDropper />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Paper className={classes.graph}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              className={classes.tabs}
            >
              <Tab
                value={0}
                label="Visualize"
                icon={
                  <HelpButton
                    asIcon
                    size="small"
                    title="What is this?"
                    url="/docs/appserver/mma-details/aom-viewer"
                  />
                }
              />
              {/* <Tab value={1} label="AOM Metadata" icon={<HelpButton asIcon size="small" title="What is this?" url="/docs/appserver/mma-details/metadata" />} /> */}
              <Tab
                value={1}
                label={
                  <span>
                    <span>Validate </span>
                    <GraphValidatorFromRecoilState textOnly />
                  </span>
                }
                icon={
                  <HelpButton
                    asIcon
                    size="small"
                    title="What is this?"
                    url="/docs/appserver/mma-details/workflows"
                  />
                }
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <GraphFromRecoilState />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box p={3}>
                <GraphValidatorFromRecoilState />
              </Box>
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </RecoilRoot>
  );
};

export default DragAndDropVisualizer;
