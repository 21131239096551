import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useMemo } from 'react';

import AgDetail from '../AgDetail';
import ArtifactGraphTable from '../tables/ArtifactGraphTable';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    marginLeft: theme.spacing(2),
    overflow: 'auto',
    position: 'relative'
  },
  paperLeft: {
    height: '100%',
    overflow: 'auto',
    position: 'relative'
  },
  spaceLeft: {
    marginLeft: theme.spacing(2)
  }
}));

const TwoPanelMediaBrowser = (props) => {
  const classes = useStyles();
  return useMemo(
    () => (
      <Grid container>
        <Grid item sm={4} md={3}>
          <Paper className={classes.paperLeft}>
            <ArtifactGraphTable useTag whichRoute="gallery" tag="test-3" />
          </Paper>
        </Grid>
        {/* <Grid item xs={4}>
          <Paper className={classes.paper}>
            <AvailableAnalytics />
          </Paper>
        </Grid> */}
        <Grid item sm={8} md={9}>
          <Paper className={classes.paper}>
            <AgDetail />
          </Paper>
        </Grid>
      </Grid>
    ),
    [classes.paper, classes.paperLeft]
  );
};

export default TwoPanelMediaBrowser;
