import { Box, Link, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useContext } from "react";

import { SettingsContext } from "../../contexts/SettingsContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
    marginLeft: theme.spacing(2),
    overflow: "auto",
    position: "relative",
  },
  paperLeft: {
    height: "100%",
    overflow: "auto",
    position: "relative",
  },
  spaceLeft: {
    marginLeft: theme.spacing(2),
  },
}));

const RequestAccess = (props) => {
  const classes = useStyles();
  const { dialogToggle } = useContext(SettingsContext);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.paperLeft}>
          <Box p={3} textAlign="center">
            <div
              style={{
                marginTop: "25vh",
                textAlign: "center",
                width: "100%",
              }}
            >
              <h2>SemaFor HMI is in limited beta. Please request access.</h2>
              <p>To request access please email help@semaforprogram.com.</p>
              <p>
                If you believe you should have access, please check your <Link style={{
                  cursor: 'pointer'
                }} onClick={() => {
                  dialogToggle(true);
                }}>connection settings</Link> and try again.
              </p>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RequestAccess;
