import fixInvalidPropsBug from './fixInvalidPropsBug';

export default function safeParseApiJson(res) {
  let json = {};
  // check for NaN and Infinity, replacing with null, then parse.
  // @todo remove when https://gitlab.semaforprogram.com/semafor/teams/ta2/lmco/appserver/-/issues/22 resolved
  try {
    json = JSON.parse(res.replace(/\bNaN\b/g, 'null').replace(/\bInfinity\b/g, 'null'));
  } catch (e) {
    console.log('invalid json from api', e, res);
  }

  if (json.nodes) {
    json.nodes = json.nodes.map(fixInvalidPropsBug);
  } else if (json.items) {
    json.items = json.items.map((g) => {
      if (g.nodes) {
        return {
          ...g,
          nodes: g.nodes.map(fixInvalidPropsBug)
        };
      }
      return g;
    });
  }

  return json;
}
