import { useKeycloak } from '@react-keycloak/web';
import React, { createContext, useMemo } from 'react';

import { useUpdatingRef } from '../hooks/useUpdatingRef';

const UsernameContext = {
  Dynamic: createContext({}),
  Static: createContext({})
};

const StaticProvider = ({ children, username }) => {
  const usernameRef = useUpdatingRef(username);

  const value = useMemo(() => ({ usernameRef }), [usernameRef]);

  return useMemo(
    () => (
      <UsernameContext.Static.Provider value={value}>{children}</UsernameContext.Static.Provider>
    ),
    [children, value]
  );
};

const DynamicProvider = ({ children }) => {
  const { keycloak } = useKeycloak();

  const username = useMemo(
    () => (keycloak?.tokenParsed?.preferred_username || undefined) ?? 'DEFAULT_USER',
    [keycloak?.tokenParsed?.preferred_username]
  );

  const value = useMemo(() => {
    return { username };
  }, [username]);
  return useMemo(
    () => (
      <UsernameContext.Dynamic.Provider value={value}>
        <StaticProvider username={username}>{children}</StaticProvider>
      </UsernameContext.Dynamic.Provider>
    ),
    [children, username, value]
  );
};

export { UsernameContext, DynamicProvider as UsernameProvider };
