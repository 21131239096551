import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { forIn, reduce } from 'lodash';
import React, { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRecoilState } from 'recoil';

import { loadedGraphsAtom } from '../../atoms';
import GraphViewCtyo from '../GraphViewCtyo';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function PickOne() {
  return (
    <div
      style={{
        marginTop: '25vh',
        textAlign: 'center',
        width: '100%'
      }}
    >
      <h2>Drag &amp; drop files to the to get started</h2>
      <ArrowBackIcon />
    </div>
  );
}

const GraphFromRecoilState = (props) => {
  const [loadedGraphs] = useRecoilState(loadedGraphsAtom);

  console.debug(`rendering graph with ${loadedGraphs.length} graphs`);

  // process evidence graphs
  const nodes = reduce(
    loadedGraphs,
    (acc, { metadata: { name }, nodes }) => [
      ...acc,
      ...nodes.map((node) => {
        const _node = {
          ...node,
          id: `${name}-${node.id}`
        };
        if (_node.graphId) {
          _node.graphId = `${name}-${_node.graphId}`;
        }
        return _node;
      })
    ],
    []
  );

  // process artifact and evidence edges the same
  const edges = reduce(
    [...loadedGraphs],
    (acc, { edges, metadata: { name } }) => [
      ...acc,
      ...edges.map((edge) => ({
        ...edge,
        id: `${name}-${edge.id}`,
        source: `${name}-${edge.source}`,
        target: `${name}-${edge.target}`
      }))
    ],
    []
  );

  // artifact graphs need a fake node created
  loadedGraphs.forEach(({ metadata: { name }, nodes: _nodes, rootNodeId }) => {
    _nodes.forEach((_node) => {
      const newNode = {
        ..._node,
        id: `${name}-${_node.id}`
      };
      nodes.push(newNode);
    });
  });

  const allAssociatedGraphs = useMemo(() => ({}), []);

  loadedGraphs.forEach(({ associatedGraphs, metadata: { name } }) => {
    forIn(associatedGraphs, (ag, key) => {
      allAssociatedGraphs[`${name}-${key}`] = ag;
    });
  });

  return useMemo(
    () => (
      <>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          {loadedGraphs.length === 0 && <PickOne />}
          {loadedGraphs.length > 0 && (
            <GraphViewCtyo
              full
              id="previewGraph"
              nodes={nodes}
              edges={edges}
              associatedGraphs={allAssociatedGraphs}
            />
          )}
        </ErrorBoundary>
      </>
    ),
    [loadedGraphs.length, nodes, edges, allAssociatedGraphs]
  );
};

export default GraphFromRecoilState;
