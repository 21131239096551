const Node = (props) => {
  return `
${props.nodeType}
  `;
  // annotations
  // graphId
  // nodeId
};

export default Node;
