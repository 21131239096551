import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { loadedGraphsAtom } from '../../atoms';
import ValidGraphChecker from './../ValidGraphChecker';

const GraphValidatorFromRecoilState = (props) => {
  const [loadedGraphs] = useRecoilState(loadedGraphsAtom);

  return useMemo(
    () => (
      <>
        {loadedGraphs.length >= 1 && (
          <>
            {loadedGraphs.length > 1 && (
              <ValidGraphChecker combinedGraphs={loadedGraphs} {...props} />
            )}
            {loadedGraphs.length === 1 && <ValidGraphChecker graph={loadedGraphs[0]} {...props} />}
          </>
        )}
      </>
    ),
    [loadedGraphs, props]
  );
};

export default GraphValidatorFromRecoilState;
