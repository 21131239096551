import { omit } from 'lodash';

export default function fixInvalidPropsBug(node) {
  let fixed = { ...node };
  if (fixed.nodeType !== 'EvImageLocBoundingPolyNode') {
    fixed = omit(fixed, ['hole']);
  }
  if (fixed.nodeType !== 'EvScore') {
    fixed = omit(fixed, ['detectionType']);
  }
  return fixed;
}
