export default function formatEdgesForGraph(edges) {
  return edges.map((edge) => {
    return {
      ...edge,
      // @todo find better solution here
      color: edge.edgeType === 'FAKE' ? '#ccc' : 'black',

      fontColor: edge.edgeType === 'FAKE' ? 'white' : 'black',
      originalEdge: edge,
      originalLink: edge
    };
  });
}
