import PropertyAnnotations from './PropertyAnnotations';

const NodeGraph = (props) => {
  return `
${props.nodeType} 
${props.analytic} v${props.version || '?'}
${PropertyAnnotations(props)}
  `;
  // analytic
  // version
};

export default NodeGraph;
