// Egs
import { default as AgAssetCollectionNode } from './AgAssetCollectionNode';
import { default as AgAudioNode } from './AgAudioNode';
import { default as AgBinaryNode } from './AgBinaryNode';
import { default as AgDecomposedDocumentNode } from './AgDecomposedDocumentNode';
import { default as AgDocumentNode } from './AgDocumentNode';
import { default as AgImageNode } from './AgImageNode';
// Ags
import { default as AgMultiMediaAssetNode } from './AgMultiMediaAssetNode';
import { default as AgReferenceNode } from './AgReferenceNode';
import { default as AgVideoNode } from './AgVideoNode';
import { default as EvAnalysisNode } from './EvAnalysisNode';
import { default as EvAnalyticModelNode } from './EvAnalyticModelNode';
import { default as EvAomLocIdNode } from './EvAomLocIdNode';
import { default as EvAttributionNode } from './EvAttributionNode';
import { default as EvAudioLocRegionNode } from './EvAudioLocRegionNode';
import { default as EvCharacterizationNode } from './EvCharacterizationNode';
import { default as EvConceptNode } from './EvConceptNode';
import { default as EvConsistencyCheckNode } from './EvConsistencyCheckNode';
import { default as EvDacNode } from './EvDacNode';
import { default as EvDetectionNode } from './EvDetectionNode';
import { default as EvFalsifierAttributionNode } from './EvFalsifierAttributionNode';
import { default as EvFusedAttributionNode } from './EvFusedAttributionNode';
import { default as EvFusedCategoryAttributionNode } from './EvFusedCategoryAttributionNode';
import { default as EvFusedCategoryCharacterizationNode } from './EvFusedCategoryCharacterizationNode';
import { default as EvFusedCategoryDetectionNode } from './EvFusedCategoryDetectionNode';
import { default as EvFusedCharacterizationNode } from './EvFusedCharacterizationNode';
import { default as EvFusedDetectionNode } from './EvFusedDetectionNode';
import { default as EvIgnoredAssetNode } from './EvIgnoredAssetNode';
import { default as EvImageLocBoundingPolyNode } from './EvImageLocBoundingPolyNode';
import { default as EvImageLocPixelMapNode } from './EvImageLocPixelMapNode';
import { default as EvReferenceNode } from './EvReferenceNode';
import { default as EvTechniqueAttributionNode } from './EvTechniqueAttributionNode';
import { default as EvTextLocSubstringListNode } from './EvTextLocSubstringListNode';
import { default as EvTextLocSubstringNode } from './EvTextLocSubstringNode';
import { default as EvVideoLocFrameNode } from './EvVideoLocFrameNode';
import { default as EvVideoLocRegionNode } from './EvVideoLocRegionNode';

const nodeType = {
  AgAssetCollectionNode,
  AgAudioNode,
  AgBinaryNode,
  AgDecomposedDocumentNode,
  AgDocumentNode,
  AgImageNode,
  // Ags
  AgMultiMediaAssetNode,
  AgReferenceNode,
  AgVideoNode,
  // Egs
  EvAnalysisNode,
  EvAnalyticModelNode,
  EvAomLocIdNode,
  EvAttributionNode,
  EvAudioLocRegionNode,
  EvCharacterizationNode,
  EvConceptNode,
  EvConsistencyCheckNode,
  EvDacNode,
  EvDetectionNode,
  EvFalsifierAttributionNode,
  EvFusedAttributionNode,
  EvFusedCategoryAttributionNode,
  EvFusedCategoryCharacterizationNode,
  EvFusedCategoryDetectionNode,
  EvFusedCharacterizationNode,
  EvFusedDetectionNode,
  EvIgnoredAssetNode,
  EvImageLocBoundingPolyNode,
  EvImageLocPixelMapNode,
  EvReferenceNode,
  EvTechniqueAttributionNode,
  EvTextLocSubstringListNode,
  EvTextLocSubstringNode,
  EvVideoLocFrameNode,
  EvVideoLocRegionNode
};

export default nodeType;
