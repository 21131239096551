let nodeColors = {
  EgToAg: {
    color: 'red',
    strokeWidth: 1
  },
  EvAnalysisNode: {
    borderWidth: 2,
    color: '#FFFFC6',
    height: 45,
    label: () => null,
    render: (node) => <p>{node.analytic}</p>,
    strokeWidth: 2
  },
  EvAomLocIdNode: { color: '#E4D4E8' },
  EvAttributionNode: {
    color: '#D0E9D2',
    fontSize: 14,
    height: 80,
    label: 'A',
    render: (node) => null
  },
  EvAudioLocRegionNode: { color: '#E4D4E8' },

  EvCharacterizationNode: {
    color: '#D0E9D2',
    fontSize: 14,
    height: 80,
    label: 'C',
    render: (node) => null
  },
  EvConceptNode: {
    color: '#85D36B',
    height: 45,
    label: ({ label }) => label
  },
  EvConsistencyCheckNode: {
    color: '#FFCC98',
    height: 80,
    render: (node) => (
      <>
        <p>Category: {node.category}</p>
      </>
    )
  },

  EvDacNode: { color: '#D0E9D2' },

  EvDetectionNode: {
    color: '#D0E9D2',
    fontSize: 14,
    height: 80,
    label: 'D',
    render: (node) => <p>{node.detectionType}</p>
  },

  EvFalsifierAttributionNode: { color: 'orange' },

  EvFusedAttributionNode: { color: '#E4D4E8' },
  EvFusedCategoryAttributionNode: { color: '#E4D4E8' },

  EvFusedCategoryCharacterizationNode: { color: '#E4D4E8' },

  EvFusedCategoryDetectionNode: { color: '#E4D4E8' },
  EvFusedCharacterizationNode: { color: '#E4D4E8' },

  EvFusedDetectionNode: { color: '#E4D4E8' },
  EvImageLocBoundingPolyNode: {
    color: '#E4D4E8',
    height: 80,
    render: (node) => (
      <>
        <p>
          Hole?: {node.hole ? 'TRUE' : 'FALSE'}
          <br />
          <br />
          Points (x, y)
          <br />
          {node.points.map((pt, idx) => (
            <span key={idx}>
              {pt.x}
              {', '}
              {pt.y}
              <br />
            </span>
          ))}
        </p>
      </>
    )
  },

  EvImageLocPixelMapNode: { color: '#E4D4E8' },

  EvReferenceNode: {
    borderColor: 'black',
    color: 'white',
    height: 45,
    render: (node) => (
      <>
        <p>
          Graph: {node.graphId}
          <br />
          Node? {node.nodeId}
        </p>
      </>
    )
  },
  EvTechniqueAttributionNode: { color: 'orange' },
  EvTextLocSubstringListNode: { color: '#E4D4E8' },
  EvTextLocSubstringNode: { color: '#E4D4E8' },
  EvVideoLocFrameNode: { color: '#E4D4E8' },
  EvVideoLocRegionNode: { color: '#E4D4E8' },

  FAKE: {
    color: '#ccc',
    strokeWidth: 0.1
  }
};

// const colors = randomColor({
//   count: size(nodeColors),
//   seed: 5,
// });

// nodeColors = mapValues(nodeColors, (c, key) => {
//   return {
//     ...c,
//     color: colors.pop(),
//   };
// });

export default nodeColors;
