import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SortIcon from '@material-ui/icons/Sort';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TuneIcon from '@material-ui/icons/Tune';
import clsx from 'clsx';
import { get } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router';
import { useToggle } from 'react-use';

import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import { TokenContext } from '../../contexts/TokenContext';
import safeParseApiJson from '../../helpers/safeParseApiJson';
import LoadEg from '../LoadEg';
import LoadWorkflowsForAg from '../LoadWorkflowsForAg';
import ScoreViewerSimple from '../ScoreViewerSimple';
import REGISTRATIONS from './../../constants/gym-registrations.json';
import ToggleAnalytic from './../ToggleAnalytic';

const { REACT_APP_BACKEND_PYTHON_ENDPOINT } = process.env;

const useStyles = makeStyles((theme) => ({
  button: {
    '& > button': {
      opacity: 0.2
    },
    '& > button.selected': {
      opacity: 1
    }
  },
  buttonCell: {
    padding: '0 6px !important'
  },
  container: {
    maxHeight: '100%'
  },
  detailsButton: {
    position: 'absolute',
    right: theme.spacing(10),
    top: theme.spacing(1)
  },
  disabledRow: {
    '& button': {
      display: 'none'
    },
    opacity: 0.2
  },
  filterButton: {
    '& > button': {
      marginRight: theme.spacing(1)
    },
    marginRight: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(2)
  },
  previewCell: {
    paddingTop: theme.spacing(3),
    position: 'relative'
  },
  relative: {
    position: 'relative'
  },
  runButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  starCell: {
    padding: '0 6px !important',
    width: 20
  },
  tableRow: {
    '&:hover button': {
      opacity: 1
    },
    cursor: 'pointer',
    transition: theme.transitions.create('backgroundColor', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.sharp
    })
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  tableRowSelected: {
    backgroundColor: theme.palette.action.hover
  },
  wrapper: {
    display: 'block'
  }
}));

const AvailableAnalytics = (props) => {
  const classes = useStyles();
  const { collectionIds } = useContext(AnalyticsContext);
  const [showCollection, toggleShowCollection] = useToggle(false);
  const { connection } = useContext(SettingsContext);
  const { token } = useContext(TokenContext.Dynamic);
  const [analytics, setAnalytics] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  let filter = {};

  const registeredComponents = REGISTRATIONS[16].map((r) => r.component.name);

  if (collectionIds) {
    filter['metadata.name'] = {
      $in: registeredComponents
    };
  }
  const fetchComponents = () =>
    fetch(`${connection}/components?filter=${JSON.stringify(filter)}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.text())
      .then(safeParseApiJson);

  const { data } = useQuery(['analytics', { filter }], fetchComponents);

  useEffect(() => {
    if (get(data, 'items')) {
      setAnalytics(get(data, 'items'));
    }
  }, [data]);

  // useEffect(() => {
  //   let _analytics = [...availableAnalytics];
  //   if (collectionIds && showCollection) {
  //     _analytics = filter(availableAnalytics, ({ name }) => collectionIds.includes(name) );
  //   }
  //   _analytics = sortBy(_analytics, ({name}) => get(runningAnalytics, [name], false) === true).reverse()
  //   setAnalytics(_analytics);
  // }, [collectionIds, showCollection]);

  return useMemo(
    () => (
      <TableContainer className={classes.container}>
        <Table size="small" aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell component="th" className={classes.buttonCell} colSpan="3">
                <span className={classes.filterButton}>
                  <IconButton>
                    <SortIcon />
                  </IconButton>
                  Default Sort
                </span>
                <span className={classes.filterButton}>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      horizontal: 'center',
                      vertical: 'bottom'
                    }}
                    transformOrigin={{
                      horizontal: 'center',
                      vertical: 'top'
                    }}
                  >
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel component="legend">Type</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={true} name="Detection" />}
                          label="Detection"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={true} name="Attribution" />}
                          label="Attribution"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={true} name="Characterization" />}
                          label="Characterization"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={true} name="Fusion" />}
                          label="Fusion"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={true} name="Explanation" />}
                          label="Explanation"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={true} name="MediaFor" />}
                          label="MediaFor"
                        />
                      </FormGroup>
                    </FormControl>
                  </Popover>
                  <IconButton onClick={handleClick}>
                    <TuneIcon />
                  </IconButton>
                  No Filter
                </span>
                <span className={classes.filterButton}>
                  <Popover
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      horizontal: 'center',
                      vertical: 'bottom'
                    }}
                    transformOrigin={{
                      horizontal: 'center',
                      vertical: 'top'
                    }}
                  >
                    <FormControl component="fieldset" className={classes.formControl}>
                      <pre>
                        {`LLR Score Min   | LLR Score Max | Color 
----------------|---------------|------
0.5             | Infinity      | Red
0.2             | 0.5           | Orange
-0.5            | 0.2           | Transparent
-Infinity       | -0.5          | Green`}
                      </pre>
                    </FormControl>
                  </Popover>
                  <IconButton onClick={handleClick2}>
                    <TuneIcon />
                  </IconButton>
                  Color Coding
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.starCell}>
                <IconButton
                  className={classes.paginationAreaButton}
                  onClick={() => toggleShowCollection()}
                >
                  {showCollection ? <StarIcon /> : <StarBorderIcon />}
                </IconButton>
              </TableCell>
              <TableCell className={classes.buttonCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {analytics.map((row) => (
              <Row row={row} key={get(row, 'metadata.name')} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [
      classes.container,
      classes.buttonCell,
      classes.filterButton,
      classes.formControl,
      classes.starCell,
      classes.paginationAreaButton,
      open,
      anchorEl,
      open2,
      anchorEl2,
      showCollection,
      analytics,
      toggleShowCollection
    ]
  );
};

function Row(props) {
  const classes = useStyles();
  const { row } = props;
  const location = useLocation();
  const queryClient = useQueryClient();
  const { agId } = useParams();
  const isSelected = useMemo(() => {
    return location.pathname === `/analytics/${get(row, 'metadata.name')}`;
  }, [location.pathname, row]);
  const mutation = useMutation(
    ({ agId, analyticName }) => {
      let formData = new FormData();
      formData.append('analytics', analyticName);
      formData.append('existing_ags', agId);
      return fetch(`${REACT_APP_BACKEND_PYTHON_ENDPOINT}/test`, {
        body: formData,
        method: 'POST',
        mode: 'no-cors'
      })
        .then((res) => res.text())
        .then((data) => {
          console.log('data', data);
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    {
      onSuccess: (_, { agId, analyticName }) => {
        console.log('onSuccess', agId, analyticName);
        queryClient.invalidateQueries(`analyticworkflows-${agId}-${analyticName}`);
        queryClient.invalidateQueries('analyticworkflows');
      }
    }
  );
  return useMemo(
    () => (
      <TableRow
        className={clsx(classes.tableRow, classes.tableRowHover, {
          [classes.tableRowSelected]: isSelected
        })}
      >
        <TableCell className={classes.buttonCell} align="center">
          <div className={classes.button}>
            <ToggleAnalytic id={get(row, 'metadata.name')} />
          </div>
        </TableCell>
        <TableCell className={classes.previewCell}>
          <div className={classes.wrapper}>
            <Typography>
              <strong>{get(row, 'metadata.name')}</strong> {row.version}{' '}
              {get(row, 'tags', []).map((t) => (
                <Chip color="primary" variant="outlined" size="small" label={t} key={t} />
              ))}
            </Typography>
            {row.summary ? row.summary : 'No Summary'}
            <br />
            {agId && (
              <LoadWorkflowsForAg
                refetchInterval={false}
                limit={1}
                renderComponent={Test}
                workflowName={get(row, 'metadata.name')}
                agId={agId}
                onRun={(payload) => {
                  mutation.mutate(payload);
                }}
                isLoading={mutation.isLoading}
              />
            )}
            {/* {agId && <Button 
              className={classes.runButton}
              variant="contained" 
              color="primary" 
              size="small"
              disabled={mutation.isLoading}
              onClick={() => {
              mutation.mutate({
                agId,
                analyticName: get(row, 'metadata.name')
              });
            }}>{mutation.isLoading ? '...' : 'Run'}</Button>} */}
          </div>
        </TableCell>
      </TableRow>
    ),
    [
      classes.tableRow,
      classes.tableRowHover,
      classes.tableRowSelected,
      classes.buttonCell,
      classes.button,
      classes.previewCell,
      classes.wrapper,
      isSelected,
      row,
      agId,
      mutation
    ]
  );
}

const Test = ({ agId, isLoading, onRun, workflowName, workflows }) => {
  return (
    <>
      {workflows && <p>Results: {workflows.length}, Most recent result:</p>}
      {
        <Button
          style={{
            position: 'absolute',
            right: 10,
            top: 8
          }}
          variant="contained"
          size="small"
          color="primary"
          disabled={isLoading || get(workflows[0], 'status.state') === 'RUNNING'}
          onClick={() => {
            onRun({
              agId,
              analyticName: workflowName
            });
          }}
        >
          {isLoading || get(workflows[0], 'status.state') === 'RUNNING' ? '...' : 'Run'}
        </Button>
      }
      {get(workflows[0], 'status.state') === 'FAILED' && (
        <Box
          p={3}
          bgcolor="background.default"
          borderColor="action.disabled"
          borderRadius={3}
          border="1px solid"
          marginBottom={3}
        >
          <Typography variant="h6">Failed</Typography>
          <p>{get(workflows[0], 'status.reason')}</p>
        </Box>
      )}

      {workflows &&
        get(workflows[0], 'status.state') !== 'FAILED' &&
        get(workflows[0], 'status.phases', []).map((phase) => {
          return (
            <Box
              key={phase.name}
              p={3}
              bgcolor="background.default"
              borderColor="action.disabled"
              borderRadius={3}
              border="1px solid"
              marginBottom={3}
            >
              <React.Fragment>
                {phase.state !== 'COMPLETE' && (
                  <Typography variant="h6" gutterBottom component="div">
                    {phase.state === 'PENDING' ? 'Running Analytic...' : phase.state}
                  </Typography>
                )}
                {phase.state !== 'PENDING' &&
                  phase.componentsRecords.map((record) => {
                    return (
                      <div key={record.name}>
                        {record.state !== 'COMPLETE' && (
                          <>
                            <p>
                              {record.state}: {record.reason}
                            </p>
                          </>
                        )}
                        {record.state === 'COMPLETE' &&
                          record.analyticOutput &&
                          record.analyticOutput.evigraphRef && (
                            <div>
                              <LoadEg
                                renderComponent={ScoreViewerSimple}
                                name={record.analyticOutput.evigraphRef.name}
                                namespace={record.analyticOutput.evigraphRef.namespace}
                              />
                              {/* <Button 
                        style={{
                          position: 'absolute',
                          right: 82,
                          top: 8
                        }}
                        size="small"
                        variant="contained" 
                        color="primary" 
                        onClick={() => {
                          history.push(`/gallery/${agId}/5/${get(workflows, '[0].metadata.name')}`)
                        }}>Workflow</Button>
                        <Button 
                        style={{
                          position: 'absolute',
                          right: 193,
                          top: 8
                        }}
                        variant="contained"
                        size="small"
                        color="primary" 
                        onClick={() => {
                          history.push(`/gallery/${agId}/5/${get(workflows, '[0].metadata.name')}`)
                        }}>Score</Button> */}
                            </div>
                          )}
                      </div>
                    );
                  })}
              </React.Fragment>
            </Box>
          );
        })}
    </>
  );
};

export default AvailableAnalytics;
