import { makeStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { drawerWidth } from "../../constants/navigation";
import { NavDrawerContext } from "../../contexts/NavDrawerContext";
import RequestAccess from "../RequestAccess";
import menus from "./../../constants/menus";
import HasAuthRole from "./../HasAuthRole";
import NavDrawer from "./../NavDrawer";
import NavDrawerToggle from "./../NavDrawer/NavDrawerToggle";
import TopBar from "./../TopBar";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(
      5
    )}px)`,
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
  },
  routeArea: {
    flexGrow: 1,
    height: '100%',
  },
  routeArea_drawerClosed: {
    marginLeft: theme.spacing(7),
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp
    }),
  },
  routeArea_drawerOpen: {
    flexGrow: 1,
    marginLeft: drawerWidth,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    }),
  },
}));

const MainContent = (props) => {
  const { open } = useContext(NavDrawerContext);
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <TopBar
        menu={
          <HasAuthRole allRoles={["hmi-access"]}>
            <NavDrawerToggle />
          </HasAuthRole>
        }
      />
      <Toolbar />
      <HasAuthRole allRoles={["hmi-access"]}>
        <NavDrawer />
      </HasAuthRole>
      <HasAuthRole allRoles={["hmi-access"]} notAuthorized={
        <RequestAccess />
      }>
        <div
          className={clsx(classes.routeArea, {
            [classes.routeArea_drawerOpen]: open,
            [classes.routeArea_drawerClosed]: !open,
          })}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/gallery" />
            </Route>
            {menus
              .reduce((acc, menu) => [...acc, ...menu.subMenu], [])
              .map(
                (menuItem) =>
                  // @todo do this with redirect
                  menuItem.nav.route && (
                    <Route exact key={menuItem.name} path={menuItem.nav.route}>
                      {menuItem.component && <menuItem.component />}
                    </Route>
                  )
              )}
          </Switch>
        </div>
      </HasAuthRole>
    </main>
  );
};

export default MainContent;
