import { FormGroup, TextField } from "@material-ui/core";
import React, { useContext } from "react";

import { NamespaceContext } from "../../contexts/NamespaceContext";

const NamespaceFilter = (props) => {
  const { namespace, setNamespace } = useContext(NamespaceContext);
  return (
    <>
      <FormGroup>
        <TextField
          id="tag-input"
          label="Filter by namespace"
          placeholder="my namespace"
          defaultValue={namespace}
          margin="dense"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          value={namespace}
          variant="outlined"
          onInput={(e) => {
            setNamespace(e.target.value);
          }}
        />
      </FormGroup>
    </>
  );
};

export default NamespaceFilter;
