import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';

import AnalyticsCatalogTable from '../tables/AnalyticsCatalogTable';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    marginLeft: theme.spacing(2),
    overflow: 'auto',
    position: 'relative'
  },
  paperLeft: {
    height: '100%',
    overflow: 'auto',
    position: 'relative'
  },
  spaceLeft: {
    marginLeft: theme.spacing(2)
  }
}));

const AnalyticsCatalogPage = (props) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.paperLeft}>
          <AnalyticsCatalogTable />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AnalyticsCatalogPage;
