import { useContext } from 'react';

import { RolesContext } from '../contexts/RolesContext.js';

const useAuthorized = (rolesArray) => {
  const { roles = [] } = useContext(RolesContext.Dynamic);
  if (!rolesArray || rolesArray.length === 0) {
    return true;
  }
  let isAuthorized = false;
  rolesArray.forEach((role) => {
    if (roles.includes(role)) {
      isAuthorized = true;
    }
  });
  return isAuthorized;
};

export default useAuthorized;
