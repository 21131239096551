import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useKeycloak } from '@react-keycloak/web';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import HasAuthRole from '../HasAuthRole';

const UserMenu = (props) => {
  const history = useHistory();
  const { initialized, keycloak } = useKeycloak();
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);

  return (
    <>
      <HasAuthRole
        notAuthorized={
          <Button
            variant="contained"
            onClick={() => {
              keycloak.login();
            }}
          >
            Sign in
          </Button>
        }
      >
        <IconButton
          aria-label="account"
          color="inherit"
          onClick={(e) => {
            setUserMenuAnchor(e.currentTarget);
          }}
        >
          <AccountCircle />
        </IconButton>
      </HasAuthRole>
      <Menu
        id="user-menu"
        anchorEl={userMenuAnchor}
        keepMounted
        open={Boolean(userMenuAnchor)}
        onClose={() => {
          setUserMenuAnchor(null);
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            if (initialized) {
              history.push('/gallery');
              keycloak.logout();
            }
            setUserMenuAnchor(null);
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon aria-label="signOut" color="inherit" />
          </ListItemIcon>
          <ListItemText primary="Signout" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
