import '@vime/core/themes/default.css';
import '@vime/core/themes/light.css';

import { Audio, DefaultUi, Player, Video } from '@vime/react';
import React, { useRef } from 'react';

const MediaPlayer = (props) => {
  const { audio, type, uri, video } = props;

  // Obtain a ref if you need to call any methods.
  const player = useRef(null);

  const onPlaybackReady = () => {
    // ...
  };

  // If you prefer hooks :)
  // const [currentTime] = usePlayerContext(player, 'currentTime', 0);

  // useEffect(() => {
  //   console.log(currentTime);
  // }, [currentTime]);

  return (
    <Player playsinline ref={player} onVmPlaybackReady={onPlaybackReady} onVmLoadStart={(e) => {
      console.log(e);
    }}>
      {audio && (
        <Audio>
          <source data-src={uri} type={type} />
        </Audio>
      )}

      {video && (
        <Video>
          <source data-src={uri} type={type} />
        </Video>
      )}

      <DefaultUi></DefaultUi>
    </Player>
  );
};

export default MediaPlayer;
