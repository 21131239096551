import PropertyAnnotations from './PropertyAnnotations';

const Node = (props) => {
  return `
${props.nodeType}
${PropertyAnnotations(props)}
  `;
  // assetReference
  // assetDataUri
  // sourceUri
  // graphId
  // nodeId
};

export default Node;
