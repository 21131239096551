import { TextField } from '@material-ui/core';
import React, { useContext } from 'react';

import { TagContext } from '../../contexts/TagContext';

const TagPanelFilter = (props) => {
  const { setTag, tag } = useContext(TagContext);
  return (
    <>
      <TextField
        id="tag-input"
        label="Filter by collection"
        placeholder="my collection"
        defaultValue=""
        margin="dense"
        size="small"
        InputLabelProps={{
          shrink: true
        }}
        value={tag}
        variant="outlined"
        onInput={(e) => {
          setTag(e.target.value);
        }}
      />
    </>
  );
};

export default TagPanelFilter;
