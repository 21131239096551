import { useKeycloak } from '@react-keycloak/web';
import get from 'lodash/get';
import React, { memo, useMemo } from 'react';

const hasRole = (tokenParsed, aRole) => {
  return get(tokenParsed, 'client_roles', []).includes(aRole);
}

/**
 * 
 * @note this is barely used, are you sure you should not be editing useAuthorized? 
 */
// @todo test and refactor. Messy logic!
const HasAuthRole = ({ allRoles, anyRole, authRole, children, notAuthorized }) => {
  const { initialized, keycloak } = useKeycloak();
  const { authenticated, tokenParsed } = keycloak;
  return useMemo(() => {
    if (!initialized) {
      return null; // loading state
    }
    if (authenticated && allRoles) {
      let granted = true;
      allRoles.forEach((role) => {
        if (!hasRole(tokenParsed, role)) {
          granted = false;
        }
      });
      if (granted) {
        return children;
      }
      return notAuthorized || <></>;
    }
    if (authenticated && anyRole) {
      let granted = false;
      anyRole.forEach((role) => {
        if (hasRole(tokenParsed, role)) {
          granted = true;
        }
      });
      if (granted) {
        return children;
      }
      // check any of the roles
    }

    if (authenticated && authRole) {
      // check this one role
      if (hasRole(tokenParsed, authRole)) {
        return children;
      }
    }

    // user is authenticated and this component used without any required roles
    // in this case works like `HasAuth` component
    if (authenticated && !anyRole && !authRole && !allRoles) {
      return children;
    }

    return notAuthorized || <></>;
  }, [authenticated, allRoles, anyRole, authRole, notAuthorized, tokenParsed, children]);
};

export default memo(HasAuthRole);
