import PropTypes from 'prop-types';
import React, { createContext, useCallback,useMemo } from 'react';
import { useLocalStorage, useLocation, useToggle } from 'react-use';

// @note we are connecting to matt2 namespace app-server for now.
// keep this in here and make configurable in future
// const { REACT_APP_APPSERVER_ENDPOINT } = process.env;

export const SettingsContext = createContext({});

export function SettingsProvider({ children }) {
  const location = useLocation();
  const [dialogOpen, dialogToggle] = useToggle(false);
  const {
    hostname,
    protocol
  } = location;
  let defaultConnection = ''
  if (location.hostname === 'hmi.semaforprogram.com') {
    // we are at the configurable program hmi
    // default to the some AppServer 
    defaultConnection = 'https://api.hmi-staging.compute.semaforprogram.com';
  } else {
    // generate AppServer from hostname
    defaultConnection = `${protocol}//api.${hostname}`
  }
  const defaultClientId = defaultConnection
    .replace(`https://api.`, 'semafor.')
    .replace(`http://api.`, 'semafor.')
    .replace('.compute.semaforprogram.com', '');
  
  const [connection, setConnection, clearConnection] = useLocalStorage(
    'v0.7.0-alpha.8__connection',
    defaultConnection
  );

  const [clientId, setClientId] = useLocalStorage(
    'v0.7.0-alpha.8__clientId',
    defaultClientId
  );

  const reset = useCallback(() => {
    setConnection(defaultConnection);
    setClientId(defaultClientId);
  }, [defaultClientId, defaultConnection, setClientId, setConnection]);

  return useMemo(
    () => (
      <SettingsContext.Provider
        value={{
          clearConnection,
          clientId,
          connection,
          defaultClientId,
          defaultConnection,
          dialogOpen,
          dialogToggle,
          reset,
          setClientId,
          setConnection
        }}
      >
        {children}
      </SettingsContext.Provider>
    ),
    [clearConnection, clientId, connection, defaultClientId, defaultConnection, dialogOpen, dialogToggle, reset, setClientId, setConnection, children]
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};
