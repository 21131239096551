import { omit } from 'lodash';
import React from 'react';

const MetadataViewer = (props) => {
  const { aom } = props;
  const metadata = omit(aom, ['figures', 'content']);
  return <pre>{JSON.stringify(metadata, null, 2)}</pre>;
};

export default MetadataViewer;
