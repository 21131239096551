import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { get } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { SettingsContext } from '../../contexts/SettingsContext';
import { TokenContext } from '../../contexts/TokenContext';
import safeParseApiJson from '../../helpers/safeParseApiJson';
import GraphPreview from '../GraphPreview';

const useStyles = makeStyles({
  graphView: {
    minWidth: 600
  },
  table: {
    minWidth: 650
  }
});

const GraphTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const { connection } = useContext(SettingsContext);
  const { token } = useContext(TokenContext.Dynamic);
  const pageSize = 10;

  const fetchGraphs = ({ pageParam = undefined }) =>
    fetch(
      `${connection}/evidencegraphs?limit=${pageSize}&continueToken=${pageParam ? pageParam : ''}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
      .then((res) => res.text())
      .then(safeParseApiJson);

  const { data, error, fetchNextPage, hasNextPage } = useInfiniteQuery(
    'evidencegraphs',
    fetchGraphs,
    {
      getNextPageParam: (lastPage) => get(lastPage, 'metadata.continueToken'),
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (newPage > page && hasNextPage) {
        fetchNextPage().then(() => {
          setPage(newPage);
        });
      } else {
        setPage(newPage);
      }
    },
    [fetchNextPage, page, hasNextPage]
  );

  return useMemo(
    () => (
      <>
        {error && 'An error has occurred: ' + error.message}
        {!error && data && data.pages && data.pages.length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[pageSize]}
                    colSpan={3}
                    count={Infinity} // @todo get from api once added
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={handleChangePage}
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Evidence Graph Name</TableCell>
                  <TableCell>Namespace</TableCell>
                  <TableCell align="center">Graph Preview</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.pages[page].items.map((row) => (
                  <TableRow key={get(row, 'metadata.name')}>
                    <TableCell component="th" scope="row">
                      {get(row, 'metadata.name')}
                    </TableCell>
                    <TableCell>{get(row, 'metadata.namespace')}</TableCell>
                    <TableCell align="center" className={classes.graphView}>
                      <GraphPreview
                        nodes={row.nodes}
                        edges={row.edges}
                        id={get(row, 'metadata.name')}
                        doLayout
                        detailedNode
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[pageSize]}
                    colSpan={3}
                    count={Infinity} // @todo get from api once added
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </>
    ),
    [classes, data, page, error, handleChangePage]
  );
};

export default GraphTable;
