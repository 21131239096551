/* eslint-disable sort-keys-fix/sort-keys-fix */
import Chip from '@material-ui/core/Chip';
import Cytoscape from 'cytoscape';
import { chain, get, size } from 'lodash';
import React, { useMemo, useState } from 'react';

import checkNodeIdsForEdgesExist from '../../helpers/checkNodeIdsForEdgesExist';
import formatEdgesForGraph from '../../helpers/formatEdgesForGraph';
import formatNodesForGraph from '../../helpers/formatNodesForGraph';
import nodeType from '../NodeType';

// @todo display image / text inconsistency
// aomLoc is json stringified start and stop
// ex: http://localhost:3001/gallery/a5b608dce8683924c8bd1e6f7ccf1d0cd16f65fd04b93ac96ca480a3097d2fb9
//

function round(num) {
  if (num !== undefined && num !== null && num.toFixed) {
    return num.toFixed(2);
  }
}

// eslint-disable-next-line no-unused-vars
function formatFromCyNode(cyNode) {
  let label = '';
  const originalNode = cyNode.data('originalNode');
  if (!nodeType[originalNode.nodeType]) {
    console.warn(`Missing nodeType formatter for ${originalNode.nodeType}`);
  } else {
    label = nodeType[originalNode.nodeType](originalNode);
  }
  return label;
}

const ScoreViewerSimple = (props) => {
  const [sortBy] = useState('EvDetectionNode');
  const [sortDir] = useState('desc');
  const { nodes = [], edges = [], showAg = true, associatedGraphs } = props;

  // @TODO DRY ======== begin stuff to DRY into helpers

  // @TODO check for valid data
  const hasAnyData = size(nodes) > 0 || size(edges) > 0;

  const fakeNodes = [];
  const fakeEdges = [];

  // add fake nodes for associated Graphs
  if (showAg && associatedGraphs) {
    nodes.forEach(({ graphId, id: _id, nodeId, nodeType }) => {
      if (nodeType === 'EvReferenceNode') {
        const targetName = get(associatedGraphs, [graphId, 'name'], null);
        if (!targetName) {
          // @todo graph is invalid
          // isValid.valid = false;
          // isValid.errors.push(`associatedGraphs.${graphId} does not exist.`);
        } else {
          // @todo here!
          // @todo remove this check
          if (nodeId === '2984603c-0437-4250-be76-b8b3d917ecd0') {
            nodeId = '0';
          }
          if (nodeId !== undefined) {
            const newEdge = {
              edgeType: '',
              source: _id,
              target: `${targetName}-${nodeId}`
            };
            fakeEdges.push(newEdge);
          } else {
            const newEdge = {
              // @todo replace with actual rootNodeId
              edgeType: '',

              source: _id,
              target: `${targetName}-${0}`
            };
            fakeEdges.push(newEdge);
          }
        }
      }
    });
  }

  // * add originalNode / link
  // * add some basic style props
  let _nodes = formatNodesForGraph([...nodes, ...fakeNodes]);
  let _edges = formatEdgesForGraph([...edges, ...fakeEdges]);

  // @todo optimize this, move outta here, into helper maybe? Use reduce maybe?
  const els = useMemo(() => {
    let ret = [];
    _nodes.forEach((node) => {
      ret.push({
        data: {
          ...node
        }
      });
    });
    _edges.forEach((edge) => {
      ret.push({
        data: {
          ...edge
        }
      });
    });
    return ret;
  }, [_nodes, _edges]);

  const isValid = checkNodeIdsForEdgesExist(_nodes, _edges);

  // ======== END @TODO DRY

  return useMemo(() => {
    if (!hasAnyData) return 'No graph data.';
    if (isValid.valid !== true) {
      return (
        <>
          <span>Invalid graph data:&nbsp;</span>
          <ul>
            {isValid.errors.map((error, idx) => (
              <li key={idx}>{error}&nbsp;</li>
            ))}
          </ul>
        </>
      );
    }

    const cy = new Cytoscape({
      elements: els,
      headless: true,
      styleEnabled: false
    });

    // + is ele1 after ele2
    const analytics = cy.nodes('[nodeType = "EvAnalysisNode"]').sort((ele1, ele2) => {
      const ele1Score = ele1.outgoers(`node[nodeType = "${sortBy}"]`);
      const ele2Score = ele2.outgoers(`node[nodeType = "${sortBy}"]`);
      // neither score
      if (!ele1Score[0] && !ele2Score[0]) {
        return -1;
      }
      if (!ele1Score[0]) {
        return 1;
      }
      if (!ele2Score[0]) {
        return -1;
      }
      const ele1LLR = ele1Score[0].data('originalNode').score.score;
      const ele2LLR = ele2Score[0].data('originalNode').score.score;
      if (ele1LLR === ele2LLR) {
        return 0;
      }
      if (ele1LLR > ele2LLR) {
        return sortDir === 'desc' ? -1 : 1;
      }
      return sortDir === 'desc' ? 1 : -1;
    });

    return (
      <div>
        {analytics.map((a) => (
          <AnalyticRow a={a} key={a.data('id')} />
        ))}
      </div>
    );
  }, [els, hasAnyData, isValid.errors, isValid.valid, sortBy, sortDir]);
};

function AnalyticRow(props) {
  const { a } = props;
  const analyticName = a.data('originalNode').analytic;
  const analyticVersion = a.data('originalNode').version;
  const dScore = a.outgoers('node[nodeType = "EvDetectionNode"]');
  const aScore = a.outgoers('node[nodeType = "EvAttributionNode"]');
  const cScore = a.outgoers('node[nodeType = "EvCharacterizationNode"]');

  const scale = {
    red: {
      high: Infinity,
      low: 0.5000000000000000001
    },
    orange: {
      high: 0.5,
      low: 0.2000000000000000001
    },
    white: {
      high: 0.2,
      low: -0.5
    },
    green: {
      high: -0.5000000000000000001,
      low: -Infinity
    }
  };

  const styles = {
    red: {
      backgroundColor: '#ef5350',
      color: 'white',
      fontWeight: 'bold'
    },
    orange: {
      backgroundColor: '#ffc107',
      color: 'white',
      fontWeight: 'bold'
    },
    white: {
      backgroundColor: 'transparent',
      color: 'black',
      fontWeight: 'bold'
    },
    green: {
      backgroundColor: '#4caf50',
      color: 'white',
      fontWeight: 'bold'
    }
  };

  function getTextFromScore(score) {
    return chain(scale).pickBy(textHasScoreRange).keys().value();

    function textHasScoreRange(rangeObj) {
      return rangeObj.low <= score && rangeObj.high >= score;
    }
  }

  const dScoreColor = getTextFromScore(
    dScore.length > 0 && round(dScore[0].data('originalNode').score.score)
  );
  const aScoreColor = getTextFromScore(
    aScore.length > 0 && round(aScore[0].data('originalNode').score.score)
  );
  const cScoreColor = getTextFromScore(
    cScore.length > 0 && round(cScore[0].data('originalNode').score.score)
  );

  function scoreDisplay(s) {
    return (
      <>
        {s.length > 0 && round(s[0].data('originalNode').score.score)}
        <span> </span>
        {s.length > 0 && s[0].data('originalNode').score.scoreType}
      </>
    );
  }

  return (
    <>
      <span>
        {analyticName} {analyticVersion}:{' '}
      </span>
      {dScore.length > 0 && (
        <Chip size="small" label={scoreDisplay(dScore)} style={{ ...styles[dScoreColor] }} />
      )}
      {aScore.length > 0 && (
        <Chip size="small" label={scoreDisplay(aScore)} style={{ ...styles[aScoreColor] }} />
      )}
      {cScore.length > 0 && (
        <Chip size="small" label={scoreDisplay(cScore)} style={{ ...styles[cScoreColor] }} />
      )}
    </>
  );
}

export default ScoreViewerSimple;
