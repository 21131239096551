import { FormGroup, TextField } from '@material-ui/core';
import React, { useContext } from 'react';

import { TagContext } from '../../contexts/TagContext';

const TagPanel = (props) => {
  const { setTag, tag } = useContext(TagContext);
  return (
    <>
      <FormGroup>
        <TextField
          id="tag-input"
          label="Filter by group"
          placeholder="test-0"
          fullWidth
          defaultValue={""}
          margin="dense"
          size="small"
          InputLabelProps={{
            shrink: true
          }}
          value={tag}
          variant="outlined"
          onInput={(e) => {
            setTag(e.target.value);
          }}
        />
      </FormGroup>
    </>
  );
};

export default TagPanel;
