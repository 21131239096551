import { find, get } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';

import { TokenContext } from '../../contexts/TokenContext';
import safeParseApiJson from '../../helpers/safeParseApiJson';
import useMinioToAppServer from '../../helpers/useMinioToAppServer';

const LoadAOMForAg = (props) => {
  const urlMaker = useMinioToAppServer();
  const { token } = useContext(TokenContext.Dynamic);
  const { ag, renderComponent: RenderComponent } = props;
  const node = ({ nodeType }) => nodeType === 'AgDecomposedDocumentNode';
  const theNode = find(ag.nodes, node);
  const uri = urlMaker(get(theNode, 'assetDataUri', ''));
  const { data, error, isLoading } = useQuery(
    uri,
    () =>
      fetch(uri, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then((res) => res.text())
        .then(safeParseApiJson),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 20 * 100 * 60
    }
  );
  return useMemo(
    () => (
      <>
        {isLoading && 'Loading AOM for AG'}
        {error && 'Error loading AOM for AG'}
        {data && <RenderComponent aom={data} {...props} />}
      </>
    ),
    [isLoading, error, data, props]
  );
};

export default LoadAOMForAg;
