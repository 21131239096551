import EvScore from './EvScore';
import PropertyAnnotations from './PropertyAnnotations';

const Node = (props) => {
  return `
${props.nodeType}
${EvScore(props.score)}
${PropertyAnnotations(props)}
  `;
  // score
  // detectionType
};

export default Node;
