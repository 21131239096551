import { useCallback, useContext } from "react";

import { SettingsContext } from "../contexts/SettingsContext";

/* eslint-disable sort-keys-fix/sort-keys-fix */
export default function useMinioToAppServer() {
  const { connection } = useContext(SettingsContext);
  return useCallback((url, auth) => {
    const replaced = url.replace('http://semafor-minio:9000/', '');
    // /XXX/YYY/AAA/BBB/CCC
    const split = replaced.split('/');
    const namespace = split.shift();
    const datastash = split.shift();
    const path = split.join('/');
    let uri = `${connection}/namespaces/${namespace}/datastashes/${datastash}/binary?path=${path}`
    if (auth) {
      uri = `${uri}&auth=${auth}`;
    }
    return uri;
  }, [connection]);
}
