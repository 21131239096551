import PropertyAnnotations from './PropertyAnnotations';

const Node = (props) => {
  return `
${props.nodeType}
${PropertyAnnotations(props)}
Time: ${props.startTime}-${props.endTime}
  `;
  // startTime
  // endTime
};

export default Node;
