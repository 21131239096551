import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

export const NavDrawerContext = createContext({});

export function NavDrawerContextProvider({ children }) {
  const [open, setOpen, clearOpen] = useLocalStorage('open', '');

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  return useMemo(
    () => (
      <NavDrawerContext.Provider
        value={{
          clearOpen,
          open,
          setOpen,
          toggleOpen
        }}
      >
        {children}
      </NavDrawerContext.Provider>
    ),
    [open, setOpen, clearOpen, children, toggleOpen]
  );
}

NavDrawerContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
