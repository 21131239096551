import Keycloak from 'keycloak-js';
import { memo, useContext, useMemo } from 'react';

import { SettingsContext } from '../contexts/SettingsContext';

// console.log(window.location.hostname);
// http://local.compute.semaforprogram.com:3001/gallery

const useSemaforKeycloak = () => {
  const { clientId } = useContext(SettingsContext);
  return useMemo(() => {
    const keycloakConfig = {
      clientId,
      realm: 'semafor',
      url: 'https://keycloak.semaforprogram.com/auth/'
    };
    return new Keycloak(keycloakConfig);
  }, [clientId]);
}

export default useSemaforKeycloak;