import { each, find, uniq } from 'lodash';

export default function checkNodeIdsForEdgesExist(nodes, edges) {
  const errors = [];

  each(edges, (edge) => {
    const validTarget = find(nodes, ({ id }) => id === edge.target);
    if (!validTarget) {
      // console.log('nodes.length', nodes.length)
      // console.log('creating node', edge.target)
      // nodes.push(formatNodesForGraph([{
      //   id: edge.target,
      //   nodeType: 'AgDecomposedDocumentNode',
      //   originalNode: {},
      // }])[0]);
      // console.log('nodes.length', nodes.length)
      errors.push(`Node ${edge.target} does not exist.`);
    }
    const validSource = find(nodes, ({ id }) => id === edge.source);
    if (!validSource) {
      // nodes.push({
      //   id: edge.source
      // })
      errors.push(`Node ${edge.source} does not exist.`);
    }
  });

  // @todo update usage
  return {
    errors: uniq(errors),
    valid: errors.length === 0
  };
}
