import React, { useMemo } from 'react';

import useAuthorized from '../../hooks/useAuthorized';
import ListItemLink from './ListItemLink';

function MenuItem({ menuItemObj, open }) {
  const canAccess = useAuthorized(menuItemObj.roles);
  // @todo re-implement hideIfNotAuthorized in less confusing way with tests
  return useMemo(() => {
    return (
      <ListItemLink
        key={menuItemObj.name}
        to={menuItemObj.nav.menu}
        primary={open ? menuItemObj.name : ''}
        icon={menuItemObj.icon}
        disabled={!canAccess}
      />
    );
  }, [canAccess, menuItemObj.icon, menuItemObj.name, menuItemObj.nav.menu, open]);
}

export default MenuItem;