const Node = (props) => {
  return `
${props.nodeType}
Time: ${props.startTime}-${props.endTime}
  `;
  // annotations
  // startTime
  // endTime
};

export default Node;
