import yaml from 'js-yaml';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { fileNameAtom, loadedGraphsAtom, messageAtom } from './../../atoms';

function Message({ message }) {
  return (
    <div>
      <h2>{message}</h2>
      <p>Accepted files include JSON or YAML output from the SemaFor Test Harness.</p>
      {/* <ArrowBackIcon /> */}
    </div>
  );
}

function FileDropper() {
  const [processed, setProcessed] = useState(0);
  const [expecting, setExpecting] = useState(0);
  const [, setMessage] = useRecoilState(messageAtom);
  const [, setFileName] = useRecoilState(fileNameAtom);
  const [, setLoadedGraphs] = useRecoilState(loadedGraphsAtom);
  const [loadedData, setLoadedData] = useState([]);
  const history = useHistory();
  useEffect(() => {
    console.debug(`Expecting ${expecting} graphs, have ${processed}`);
    if (expecting === processed) {
      console.debug('DONE');
      console.debug(`Data should be ${expecting}, is ${loadedData.length}`);
      setLoadedGraphs(loadedData);
    }
  }, [expecting, processed, setLoadedGraphs, loadedData]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      // go to drag and drop page if not already there
      history.push('/drag-and-drop');
      // clear current graphs
      setLoadedGraphs([]);
      setLoadedData([]);
      setProcessed(0);
      setExpecting(acceptedFiles.length);
      console.debug(`Setting expectations to ${acceptedFiles.length} graphs`);

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        const handleFileRead = (e) => {
          const content = reader.result;
          // parse the maybe json
          let data;
          try {
            data = JSON.parse(content);
          } catch (e) {
            // try parsing as yaml
            console.log(e);
            console.warn('invalid json, trying as yaml');
            try {
              // try to parse as YAML to json
              data = yaml.safeLoad(content);
            } catch (ee) {
              console.log(ee);
              data = null;
              setFileName(undefined);
              setMessage('Invalid file');
              console.warn('invalid yaml');
              setProcessed((_processed) => _processed + 1);
            }
          }

          // parsing was not successful
          if (!data) {
            return;
          }
          setMessage('File ingest success');

          // support app message, or output graph.
          // in message graph will be under key `evigraph`
          // in output graph, its top level
          if (data.evigraph) {
            data = data.evigraph;
          }
          setLoadedData((existing) => [...existing, data]);
          setProcessed((_processed) => _processed + 1);
        };
        reader.onloadend = handleFileRead;
        reader.readAsText(file);
      });
    },
    [setFileName, setMessage, history, setLoadedGraphs]
  );
  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      style={{
        flexGrow: 1,
        height: '50%',
        margin: '0 0',
        textAlign: 'center'
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div
        style={{
          marginTop: '25vh',
          textAlign: 'center',
          width: '100%'
        }}
      >
        <Message message={isDragActive ? 'Drop files here' : 'Drag & drop files here'} />
        {/* {graphs.length === 0 && <Message />}
        {graphs && graphs.map((g) => {
          return (<p>{g.kind}</p>)
        })} */}
      </div>
    </div>
  );
}

export default FileDropper;
